import React from "react";
import async from "./components/Async";
import { Outlet } from "react-router-dom";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";

// Error components
import Page404 from "./pages/auth/Page404";
import MainPage from "./pages/documents/MainPage";
import { WelcomePage } from "./pages/auth/WelcomePage";
import { PhoneCodeSignInPage } from "./pages/auth/PhoneCodeSignInPage";

// Protected routes
const MainLayout = async(() => import("./layouts/MainLayout"));

const routes = [
  {
    path: "",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: (
          <GuestGuard>
            <WelcomePage />
          </GuestGuard>
        ),
      },
      {
        path: ":authParams",
        element: (
          <GuestGuard>
            <PhoneCodeSignInPage />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "ticket",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":ticketId",
        element: <MainLayout />,
        children: [
          {
            path: "",
            element: <MainPage />,
          },
        ],
      },
    ],
  },
  {
    path: "type",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":applicantType",
        element: <MainLayout />,
        children: [
          {
            path: "",
            element: <MainPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
