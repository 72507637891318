export function getProgress(stages, config, ticket) {
  return stages?.map((stage) => {
    if (stage.name !== "Documents") {
      const consumerStage = stage?.name === "Consumer";
      return {
        name: stage.name,
        status: ticket?.stages?.find((s) =>
          consumerStage ? s?.name === "Orientation" : s?.name === stage?.name
        )?.state,
        required: stage.required,
      };
    } else {
      const configItemProgress = config?.map((doc) => {
        const { name, nameToShow } = doc;

        const documentToRender = getConfigItemProgress(doc, ticket?.files);
        const requiredFiles = documentToRender?.filter((x) => x.required);
        const requiredFilesUploaded = requiredFiles?.filter(
          (x) => x.file
        ).length;

        const isItemComplete = requiredFilesUploaded === requiredFiles?.length;

        const status = isItemComplete
          ? "completed"
          : requiredFilesUploaded &&
            requiredFilesUploaded < requiredFiles?.length
          ? "inprogress"
          : "incomplete";

        return {
          types: documentToRender,
          name,
          nameToShow,
          status,
          requiredFiles: requiredFiles?.length ?? 0,
          requiredFilesUploaded,
        };
      });

      const documentsProgress = {
        name: stage.name,
        status: configItemProgress?.every((c) => c?.status === "completed")
          ? "completed"
          : configItemProgress?.some(
              (c) => c?.requiredFiles && c?.requiredFilesUploaded
            )
          ? "inprogress"
          : "incomplete",
        items: configItemProgress,
      };
      return documentsProgress;
    }
  });

  function getConfigItemProgress(doc, uploadedFiles) {
    const documentToRender = doc?.types?.map((type) => {
      const files = (uploadedFiles || [])
        .filter((x) => {
          return (
            type.subTypes?.some(
              (subType) =>
                x.documentType === subType.nameToShow && x.side === type.side
            ) ||
            x.subType === type.name ||
            x.documentType === type.nameToShow
          );
        })
        .reduce((obj, cur) => {
          return cur.side ? { ...obj, [cur.side]: cur } : cur;
        }, null);

      return { ...type, type: doc?.name, file: files };
    });
    return documentToRender;
  }
}

export function getTotalCompletedPercentage(progress) {
  const eachItemPercentage = 100 / progress?.length;
  const totalSimpleItemsCompleted = progress?.filter(
    (p) => !p?.items && p?.status === "completed"
  )?.length;
  const simpleItemsTotalPercentage =
    eachItemPercentage * totalSimpleItemsCompleted;
  const itemsWithSubItems = progress?.filter((p) => p?.items) || [];

  let subItemsTotalPercentage = 0;
  for (const itemWithSubItems of itemsWithSubItems) {
    const eachSubItemPercentage =
      eachItemPercentage / itemWithSubItems?.items?.length;
    const totalSubItemsCompleted = itemWithSubItems?.items?.filter(
      (p) => p?.status === "completed"
    );
    const itemsWithSubItemsTotalPercentage =
      eachSubItemPercentage * totalSubItemsCompleted?.length;
    subItemsTotalPercentage =
      subItemsTotalPercentage + itemsWithSubItemsTotalPercentage;
  }

  const totalProgress = simpleItemsTotalPercentage + subItemsTotalPercentage;
  return totalProgress;
}
