import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, MenuItem, Select } from "@mui/material";

import { CaretDownIcon, CaretRightIcon } from "../../assets/icons";
import { Header } from "../../components/auth/Header";
import axios from "../../utils/axios";

import "./welcomePage.less";

export const WelcomePage = () => {
  const [t] = useTranslation("global");
  const [applicantTypes, setApplicantTypes] = useState([]);
  const [applicantType, setApplicantType] = useState("");
  const navigate = useNavigate();

  useEffect(initialEffect, []);

  return (
    <div className="h-full flex max-w-screen-xl mx-auto flex-col gap-2">
      <div className="header-container">
        <Header />
      </div>
      <div className="flex justify-between flex-col h-full p-6">
        <div className="flex flex-col gap-4 justify-center my-auto">
          <div className="flex flex-col items-center gap-4 justify-center ">
            <div>
              <h2 className="font-bold text-2xl">{t("welcome")}</h2>
            </div>
            <div className="text-center">
              {t("welcomeDescriptionApplicant")}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4">
          <div className="select-applicant-container">
            <label className="leading-7 font-bold">
              {t("selectApplicantType")}
            </label>
            <Select
              value={applicantType}
              className="input-element text-center applicant-type-select h-9"
              IconComponent={() => <CaretDownIcon color={"black"} />}
              onChange={(e) => setApplicantType(e.target.value)}
            >
              {applicantTypes.map((type) => (
                <MenuItem key={type.name} value={type.nameToShow}>
                  {type.nameToShow}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Button
            className="input-element"
            size="medium"
            variant="contained"
            color="secondary"
            endIcon={<CaretRightIcon />}
            onClick={() => applicantTypeRedirectionHandler()}
            disabled={!applicantType}
          >
            {t("start")}
          </Button>
        </div>
      </div>
    </div>
  );

  function initialEffect() {
    async function getApplicantTypes() {
      const { data } = await axios({
        url: `/tickets/ats/main-flow-applicant-types`,
      });
      setApplicantTypes(data.applicantTypes);
      setApplicantType(data.applicantTypes[0].nameToShow);
    }
    getApplicantTypes();
  }

  function applicantTypeRedirectionHandler() {
    navigate(`/type=${applicantType}`);
  }
};
