import React from "react";

const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66732 1.83203C4.44566 1.83203 1.83398 4.4437 1.83398 7.66536C1.83398 10.887 4.44566 13.4987 7.66732 13.4987C10.889 13.4987 13.5007 10.887 13.5007 7.66536C13.5007 4.4437 10.889 1.83203 7.66732 1.83203ZM0.833984 7.66536C0.833984 3.89142 3.89337 0.832031 7.66732 0.832031C11.4413 0.832031 14.5007 3.89142 14.5007 7.66536C14.5007 11.4393 11.4413 14.4987 7.66732 14.4987C3.89337 14.4987 0.833984 11.4393 0.833984 7.66536Z"
        fill="#B3B3B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9804 12.9785C13.1757 12.7832 13.4923 12.7832 13.6875 12.9785L15.0209 14.3118C15.2161 14.5071 15.2161 14.8237 15.0209 15.0189C14.8256 15.2142 14.509 15.2142 14.3138 15.0189L12.9804 13.6856C12.7852 13.4903 12.7852 13.1737 12.9804 12.9785Z"
        fill="#B3B3B3"
      />
    </svg>
  );
};

export default Search;
