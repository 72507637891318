import React from "react";

const FinishStatus = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.6872 6.68612C10.7363 6.64034 10.7757 6.58514 10.8031 6.52381C10.8304 6.46248 10.8451 6.39627 10.8463 6.32913C10.8475 6.262 10.8351 6.19531 10.81 6.13305C10.7848 6.07079 10.7474 6.01424 10.6999 5.96676C10.6524 5.91928 10.5959 5.88185 10.5336 5.8567C10.4714 5.83156 10.4047 5.81921 10.3375 5.82039C10.2704 5.82157 10.2042 5.83627 10.1429 5.8636C10.0815 5.89093 10.0263 5.93033 9.98055 5.97945L7.00055 8.95945L6.02055 7.97945C5.92577 7.89113 5.8004 7.84305 5.67087 7.84533C5.54133 7.84762 5.41774 7.9001 5.32613 7.9917C5.23453 8.08331 5.18205 8.2069 5.17977 8.33644C5.17748 8.46597 5.22556 8.59133 5.31388 8.68612L6.64722 10.0195C6.74097 10.1131 6.86805 10.1657 7.00055 10.1657C7.13305 10.1657 7.26013 10.1131 7.35388 10.0195L10.6872 6.68612Z"
        fill="#31A619"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9987 0.832031C4.0407 0.832031 0.832031 4.0407 0.832031 7.9987C0.832031 11.9567 4.0407 15.1654 7.9987 15.1654C11.9567 15.1654 15.1654 11.9567 15.1654 7.9987C15.1654 4.0407 11.9567 0.832031 7.9987 0.832031ZM1.83203 7.9987C1.83203 6.3632 2.48173 4.79468 3.63821 3.63821C4.79468 2.48173 6.3632 1.83203 7.9987 1.83203C9.6342 1.83203 11.2027 2.48173 12.3592 3.63821C13.5157 4.79468 14.1654 6.3632 14.1654 7.9987C14.1654 9.6342 13.5157 11.2027 12.3592 12.3592C11.2027 13.5157 9.6342 14.1654 7.9987 14.1654C6.3632 14.1654 4.79468 13.5157 3.63821 12.3592C2.48173 11.2027 1.83203 9.6342 1.83203 7.9987Z"
        fill="#31A619"
      />
    </svg>
  );
};

export default FinishStatus;
