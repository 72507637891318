export const setAppTitle = () => {
  const { REACT_APP_ENV } = process.env;

  const titleMap = {
    stage: "ARLA Application",
    dev: "🔐🗃️ ARLA Application Development 🔐🗃️",
    local: "🔓🧑‍💻 ARLA Application Local 🔓🧑‍💻",
  };

  const title = titleMap[REACT_APP_ENV] || "ARLA Application";
  document.title = title;
};
