import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import axios from "axios";
import { APP_ENV, getApiGateway } from "./utils/axios";

const resourcesCache = {};
let fetchPromises = {};

const getTranslationsUrl = async (language) => {
  try {
    const response = await axios.get(
      `${getApiGateway(APP_ENV).mainAPI.replace(
        ".application",
        ""
      )}/tickets/ats/getLanguage`,
      {
        params: { language },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.error("Error fetching translation URL:", error);
    return null;
  }
};

export const getTranslationsUrlByTicket = async (ticketId) => {
  try {
    const response = await axios.get(
      `${getApiGateway(APP_ENV).mainAPI.replace(
        ".application",
        ""
      )}/tickets/ats/getLanguage`,
      {
        params: { ticketId },
      }
    );
    return response.data?.result;
  } catch (error) {
    console.error("Error fetching translation URL:", error);
    return null;
  }
};

const initI18n = async () => {
  const defaultLanguage = "en";
  const translationsResponse = await getTranslationsUrl(defaultLanguage);
  if (!translationsResponse?.url) {
    console.error("No translation URL found");
    return;
  }

  resourcesCache[defaultLanguage] = translationsResponse.url;

  i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      interpolation: { escapeValue: false },
      lng: localStorage.getItem("languageKey") ?? defaultLanguage,
      fallbackLng: defaultLanguage,
      backend: {
        loadPath,
      },
    });
};

const loadPath = async (lng) => {
  const languageKey = Array.isArray(lng) ? lng[0] : lng;

  if (resourcesCache[languageKey]) {
    return resourcesCache[languageKey];
  }

  if (!fetchPromises[languageKey]) {
    fetchPromises[languageKey] = getTranslationsUrl(languageKey)
      .then((response) => {
        if (response?.url) {
          resourcesCache[languageKey] = response.url;
          return response.url;
        } else {
          return null;
        }
      })
      .finally(() => {
        delete fetchPromises[languageKey];
      });
  }
  return fetchPromises[languageKey];
};

initI18n();

export default i18n;
