import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const loading = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});
export const { actions, reducer } = loading;
