import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { t } from "i18next";
import { Button } from "@mui/material";

import { RefreshIcon } from "../../assets/icons";
import { actions } from "../../redux/slices/loadingSlice";
import { unformatPhoneValue } from "../../utils/phone";
import useAuth from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import { OTP } from "./OTP";
import useAuthParams from "../../hooks/useAuthParams";

export default function EnterCode({ phoneNumber, selectedTicket }) {
  const [otp, setOtp] = useState("");
  const { enqueueSnackbar: notification } = useSnackbar();
  const { loading } = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();
  const {
    createCode,
    createCodeByPhone,
    authenticateWithTokenAndTicketId,
    getTokenByCodeAndTicket,
    getTokenByCodeAndPhone,
  } = useAuth();
  const [loginRedirect, setLoginRedirect] = useState(false);
  const { value, isTypeParam } = useAuthParams();

  useEffect(loginRedirectEffect, [loginRedirect]);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-4">
        <h2 className="title">{t("codeTitle")}</h2>
        <p>
          {t("checkCodeAt")}
          <strong> {phoneNumber}</strong>
        </p>
        <div className="flex gap-2.5">
          <OTP
            setLoginRedirect={setLoginRedirect}
            value={otp}
            onChange={setOtp}
            length={4}
          />
        </div>
        <div className="flex items-center gap-4">
          <span>{t("codeResend")}</span>
          <div
            onClick={getLoginCode}
            className="flex items-center gap-2 text-primary cursor-pointer"
            disabled={loading}
          >
            <RefreshIcon />
            {t("resend")}
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 bg-white pb-2">
        <Button
          size="medium"
          variant="contained"
          color="secondary"
          onClick={handleEnterCode}
          disabled={loading || otp.length < 4}
          className="w-full"
        >
          {t("verify")}
        </Button>
      </div>
    </div>
  );

  function loginRedirectEffect() {
    if (loginRedirect) {
      handleEnterCode();
      setLoginRedirect(false);
    }
  }

  async function getLoginCode() {
    dispatch(actions.setLoading(true));
    if (selectedTicket) {
      await createCode(selectedTicket);
    } else {
      const unformattedPhone = "+1" + unformatPhoneValue(phoneNumber);
      await createCodeByPhone(unformattedPhone);
    }
    dispatch(actions.setLoading(false));
  }

  async function handleEnterCode() {
    try {
      dispatch(actions.setLoading(true));
      const unformattedPhone = "+1" + unformatPhoneValue(phoneNumber);
      const token = selectedTicket
        ? await getTokenByCodeAndTicket(otp, selectedTicket)
        : await getTokenByCodeAndPhone(otp, unformattedPhone);
      dispatch(actions.setLoading(false));
      login(token);
    } catch (error) {
      const message = error.message || t("incorrectCode");
      notification(message, {
        variant: "error",
      });
      dispatch(actions.setLoading(false));
    }
  }

  function login(token) {
    const unFormattedPhone = "+1" + unformatPhoneValue(phoneNumber);
    localStorage.setItem("phoneNumber", unFormattedPhone);
    const applicantType = isTypeParam ? value : undefined;
    authenticateWithTokenAndTicketId(token, selectedTicket, applicantType);
  }
}
