export const findLastInArray = (array, condition) => {
  if (array.findLast) return array.findLast(condition);
  else return [...array].reverse().find(condition);
};

export const mapApplicantType = (type) => {
  const pattern = /\s+|\//g;
  const mappedType = type.replace(pattern, "-");
  return mappedType;
};

export function extractClientFromUrl() {
  const regex = /\/[^/]+\/([^/]+)/;
  const match = regex.exec(window.location.href);
  if (match && match.length > 1) {
    return match[1] ?? localStorage.getItem("client");
  }

  return null; // Return null if no match found
}
export function inProdHost() {
  const { hostname } = document.location;

  if (!hostname) {
    return false;
  }

  return !(
    hostname.includes("dev") ||
    hostname.includes("localhost") ||
    hostname.includes("staging")
  );
}

/**
 * @param {*} array any[]
 * @param {*} handler  (item: any, i: number, currentBatch: any[]) => Promise<any>,
 * @param {*} size 20
 * @param {*} results  any[] = []
 * @returns Promise<any[]>
 */
export async function promiseByBatch(array, handler, size = 20, results = []) {
  if (!Array.isArray(array) || !array.length || size === 0) {
    return results;
  }
  const todo = array.splice(0, size);
  const promises = todo.map(handler);
  const batchResults = await Promise.all(promises);
  results = results.concat(batchResults);
  return promiseByBatch(array, handler, size, results);
}
