import React, { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { formatPhoneValue } from "../../utils/phone";
import { getTotalCompletedPercentage } from "../../utils/ticket";
import { ProgressBar } from "../shared/ProgressBar";
import {
  CaretLeftIcon,
  IdentificationIcon,
  PhoneIcon,
  UserIcon,
} from "../../assets/icons";

import "./header.less";

export const Header = () => {
  const {
    config,
    ticket,
    progress,
    stageName,
    selectedType,
    selectedStage,
    setSelectedType,
    setSelectedStage,
  } = useOutletContext();
  const applicant = ticket?.applicant;
  const [totalCompletedPercentage, setTotalCompletedPercentage] = useState(0);
  const [t] = useTranslation("global");

  useEffect(progressEffect, [progress]);

  return (
    <div className="header flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        {stageName !== "Application" && selectedStage !== 0 && (
          <div>
            <Button onClick={handleBack} startIcon={<CaretLeftIcon />}>
              {t("goBack")}
            </Button>
          </div>
        )}
        <div className="flex flex-col ticket-info">
          <div>
            {!isNaN(totalCompletedPercentage) && (
              <h3 className="font-medium">
                {t("completedInfo").toUpperCase()} ({totalCompletedPercentage}%)
              </h3>
            )}
            <div className="flex w-full gap-2">
              <ProgressBar
                value={totalCompletedPercentage}
                width="100%"
                color="secondary"
              />
            </div>
          </div>
          {applicant && selectedStage === 0 && (
            <div className="mb-6">
              <h2 className="text-2xl font-bold text-primary">
                {t("welcomeUser", {
                  usr: applicant.firstName,
                })}
              </h2>
              <label className="text-sm">
                <div className="flex gap-2 items-center">
                  <PhoneIcon />
                  {formatPhoneValue(ticket?.applicant?.primaryPhone)}
                </div>
              </label>
              {ticket?.applicant?.ssn ? (
                <label className="text-sm">
                  <div className="flex gap-2 items-center">
                    <IdentificationIcon />
                    {`XXX-XX-${ticket?.applicant?.ssn?.slice(-4)}`}
                  </div>
                </label>
              ) : null}
              <label className="text-sm">
                <div className="flex gap-2 items-center">
                  <UserIcon />
                  {ticket?.type}
                </div>
              </label>
            </div>
          )}
        </div>
      </div>
      {stageName && (
        <div className="header__stage w-full flex justify-center items-center p-sm rounded-xl bg-primaryLight">
          <div className="flex flex-col justify-center">
            <h2 className="text-white text-xl font-bold text-center">
              {t(stageName.toLowerCase())}
            </h2>
            {selectedType !== null && (
              <h3 className="text-white text-base text-center">
                {t(config[selectedType].name)}
              </h3>
            )}
          </div>
        </div>
      )}
    </div>
  );

  function handleBack() {
    if (stageName !== "Documents" || selectedType === null) {
      setSelectedStage(0);
    }
    setSelectedType(null);
  }
  function progressEffect() {
    const totalCompletedPercentage = Math.round(
      getTotalCompletedPercentage(progress)
    );
    setTotalCompletedPercentage(totalCompletedPercentage);
  }
};
