import React from "react";

const Upload = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66536 5.99724C5.66688 6.27338 5.44426 6.49847 5.16812 6.49999C4.4392 6.50401 3.92246 6.52302 3.53034 6.5951C3.15246 6.66455 2.93377 6.77603 2.77159 6.93822C2.58693 7.12287 2.4666 7.38199 2.40082 7.87107C2.33309 8.37458 2.33203 9.04186 2.33203 9.99867V10.6653C2.33203 11.6225 2.33309 12.2898 2.40082 12.7933C2.46659 13.2823 2.58691 13.5416 2.77179 13.7266C2.95597 13.9107 3.21492 14.0309 3.7041 14.0966C4.20774 14.1643 4.87524 14.1653 5.83203 14.1653H11.1654C12.1222 14.1653 12.7897 14.1643 13.2933 14.0966C13.7827 14.0309 14.0416 13.9106 14.2258 13.7264C14.4105 13.5418 14.5308 13.2827 14.5966 12.7935C14.6643 12.2899 14.6654 11.6225 14.6654 10.6653V9.99867C14.6654 9.04186 14.6643 8.37458 14.5966 7.87107C14.5308 7.38199 14.4105 7.12287 14.2258 6.93822C14.0636 6.77603 13.8449 6.66455 13.4671 6.5951C13.0749 6.52302 12.5582 6.50401 11.8293 6.49999C11.5531 6.49847 11.3305 6.27338 11.332 5.99724C11.3336 5.7211 11.5587 5.49848 11.8348 5.50001C12.5559 5.50399 13.1568 5.52131 13.6478 5.61157C14.1531 5.70445 14.5831 5.8813 14.9329 6.23111C15.3343 6.63246 15.5069 7.13768 15.5877 7.73776C15.6654 8.31571 15.6654 9.05049 15.6654 9.96207V10.7019C15.6654 11.6138 15.6654 12.3488 15.5877 12.9268C15.5069 13.527 15.3343 14.0322 14.9329 14.4336C14.5318 14.8347 14.0264 15.0071 13.4264 15.0877C12.8484 15.1654 12.1135 15.1653 11.2019 15.1653H5.79549C4.88387 15.1653 4.14895 15.1654 3.57096 15.0877C2.97099 15.0071 2.46562 14.8347 2.06448 14.4336C1.66335 14.032 1.49047 13.5267 1.40974 12.9266C1.33201 12.3486 1.33202 11.6138 1.33203 10.7019V9.96207C1.33202 9.0505 1.33201 8.31571 1.40974 7.73776C1.49046 7.13768 1.66313 6.63246 2.06448 6.23111C2.41429 5.8813 2.84427 5.70445 3.34956 5.61157C3.8406 5.52131 4.44153 5.50399 5.16261 5.50001C5.43875 5.49848 5.66383 5.7211 5.66536 5.99724Z"
        fill={props.fill || "currentColor"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50001 0.832031C8.64597 0.832031 8.78465 0.895813 8.87964 1.00664L10.8796 3.33997C11.0593 3.54963 11.0351 3.86528 10.8254 4.04499C10.6157 4.2247 10.3001 4.20042 10.1204 3.99076L9.00001 2.68366V9.9987C9.00001 10.2748 8.77615 10.4987 8.50001 10.4987C8.22387 10.4987 8.00001 10.2748 8.00001 9.9987V2.68366L6.87964 3.99076C6.69993 4.20042 6.38428 4.2247 6.17461 4.04499C5.96495 3.86528 5.94067 3.54963 6.12038 3.33997L8.12038 1.00664C8.21537 0.895813 8.35405 0.832031 8.50001 0.832031Z"
        fill={props.fill || "currentColor"}
      />
    </svg>
  );
};

export default Upload;
