import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";

import { actions } from "../../redux/slices/languageSlice";
import { CaretDownIcon, GlobalIcon, SearchIcon } from "../../assets/icons";
import { changeLanguage } from "i18next";
import { LANGUAGES } from "../../utils/languages";

import "./languageSelection.less";

export const LanguageSelection = ({ color }) => {
  const dispatch = useDispatch();
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredLanguages, setFilteredLanguages] = useState(LANGUAGES);
  const [searchTerm, setSearchTerm] = useState("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        className="language-container"
        onClick={(e) => {
          setPopoverOpen(!popoverOpen);
          handleOpenPopover(e);
        }}
      >
        <Button
          style={{ color }}
          component="label"
          role={undefined}
          variant="text"
          tabIndex={-1}
          startIcon={<GlobalIcon color={color} />}
          endIcon={<CaretDownIcon color={color} />}
          aria-describedby={id}
        >
          {
            LANGUAGES[localStorage.getItem("languageKey") ?? selectedLanguage]
              ?.nativeName
          }
        </Button>
        <Popover
          id={id}
          onClose={handleClosePopover}
          anchorEl={anchorEl}
          open={popoverOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              width: "228px",
              marginTop: ".5rem",
              borderRadius: "8px",
              overflow: "hidden",
            },
          }}
        >
          <div className="language-filter-container">
            <TextField
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleSearch(e.target.value)}
              value={searchTerm}
              className="language-filter"
              placeholder="Search"
              style={{ border: "none" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  height: "36px",
                  border: "none",
                },
              }}
            />
          </div>
          <List className="overflow-auto overflow-x-hidden language-list">
            {Object.keys(filteredLanguages).map((key) => (
              <ListItem
                key={key}
                onClick={() => changeLanguageHandler(key)}
                className={`${
                  key ===
                  (localStorage.getItem("languageKey") ?? selectedLanguage)
                    ? "language-item-active"
                    : "language-item-non-active"
                } language-item cursor-pointer`}
              >
                <ListItemText primary={LANGUAGES[key].nativeName} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    </>
  );

  function changeLanguageHandler(languageKey) {
    if (!languageKey) {
      return;
    }
    dispatch(actions.setLanguage(languageKey));
    changeLanguage(languageKey);
    localStorage.setItem("languageKey", languageKey);
  }

  function handleSearch(value) {
    setSearchTerm(value);
    if (!value) {
      setFilteredLanguages(LANGUAGES);
      return;
    }

    const newLanguages = Object.keys(LANGUAGES)
      .filter((key) =>
        LANGUAGES[key].name.toLowerCase().includes(value.toLowerCase())
      )
      .reduce((acc, key) => {
        acc[key] = LANGUAGES[key];
        return acc;
      }, {});

    setFilteredLanguages(newLanguages);
  }

  function handleOpenPopover(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }
};
