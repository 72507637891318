import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { changeLanguage } from "i18next";
import { Button } from "@mui/material";

import { actions } from "../../redux/slices/ticketDetailSlice";
import { actions as languageActions } from "../../redux/slices/languageSlice";
import { actions as loadingActions } from "../../redux/slices/loadingSlice";
import { formatPhoneValue } from "../../utils/phone";
import { getTranslationsUrlByTicket } from "../../i18n";
import { Header } from "../../components/auth/Header";
import { mapApplicantType } from "../../utils/functions";
import { PhoneCodeSelection } from "../../components/auth/PhoneCodeSelection";
import { SelectTicket } from "../../components/auth/SelectTicket";
import { useSnackbar } from "notistack";
import axios from "../../utils/axios";
import CaretLeft from "../../assets/icons/CaretLeft";
import useAuth from "../../hooks/useAuth";
import EnterCode from "../../components/auth/EnterCode";

import "./phoneCodeSignInPage.less";
import useAuthParams from "../../hooks/useAuthParams";

export const PhoneCodeSignInPage = () => {
  const { enqueueSnackbar: notification } = useSnackbar();
  const { createCode } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneValue, setPhoneValue] = useState("");
  const [foundTickets, setFoundTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [t] = useTranslation("global");
  const { authParams } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { param, value, hasParams, isTypeParam, isTicketParam } =
    useAuthParams();

  useEffect(currentStepEffect, [currentStep]);
  useEffect(initialEffect, [dispatch, authParams]);

  const stepProps = {
    phoneNumber: phoneValue,
    nextStepHandler: nextStepHandler,
    selectedTicket: selectedTicket,
    tickets: foundTickets,
  };

  const phoneCodeStepsMap = {
    1: (
      <PhoneCodeSelection
        {...stepProps}
        setInputValue={setPhoneValue}
        setFoundTickets={setFoundTickets}
      />
    ),
    2: <SelectTicket {...stepProps} setSelectedTicket={setSelectedTicket} />,
    3: <EnterCode {...stepProps} />,
  };

  return (
    <div className="h-full flex max-w-screen-xl mx-auto flex-col gap-2">
      <div className="header-container">
        <Header />
      </div>
      <div
        className={`flex flex-col items-center
        ${
          currentStep === 1 || currentStep === 3
            ? "justify-evenly h-3/4 overflow-auto"
            : currentStep === 2
            ? "justify-evenly h-3/4"
            : "justify-evenly h-3/4"
        }`}
      >
        <div className="w-full px-6 h-full flex flex-col">
          {currentStep !== 1 && currentStep !== 4 && (
            <Button
              className="back-button max-w-fit pl-1 justify-start gap-0"
              startIcon={<CaretLeft />}
              onClick={previousStepHandler}
            >
              {t("back")}
            </Button>
          )}
          {phoneCodeStepsMap[currentStep]}
        </div>
      </div>
    </div>
  );

  function initialEffect() {
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("applicantType");
    async function getApplicantTypes() {
      const { data } = await axios({
        url: `/tickets/ats/main-flow-applicant-types`,
      });

      if (isTypeParam) {
        const typeExists = data.applicantTypes.find(
          (type) => type.nameToShow.toLowerCase() === value.toLowerCase()
        );

        if (!typeExists) {
          navigate("/");
          return;
        } else {
          navigate("/type=" + typeExists?.nameToShow);
        }

        localStorage.setItem(
          "redirectOnLogout",
          "/type=" + typeExists?.nameToShow
        );
      }

      dispatch(actions.setApplicantTypes(data.applicantTypes));
    }
    getApplicantTypes();

    if (hasParams) {
      if (isTicketParam) {
        getApplicantPhoneAndTicketType(value);
        getTranslationsForTicket(value);
        return;
      }
      if (isTypeParam) {
        return;
      }
    }
    localStorage.removeItem("redirectOnLogout");
    navigate("/");
  }

  async function getTranslationsForTicket(ticketId) {
    const response = await getTranslationsUrlByTicket(ticketId);
    dispatch(languageActions.setLanguage(response.languageKey));
    localStorage.setItem("languageKey", response.languageKey);
    changeLanguage(response.languageKey);
  }

  async function getApplicantPhoneAndTicketType(ticketId) {
    try {
      const { data } = await axios({
        url: `tickets/ats/getApplicantPhoneAndTicketType/${ticketId}`,
      });

      if (data) {
        const formattedPhone = formatPhoneValue(data.phone);
        const mappedType = mapApplicantType(data.ticketType);
        localStorage.setItem("redirectOnLogout", "/type=" + mappedType);
        await getCodeAndContinue(ticketId);
        setPhoneValue(formattedPhone);
        return;
      }

      navigate("/");
    } catch (error) {
      notification(error, {
        variant: "error",
      });
    }
  }

  async function getCodeAndContinue(ticketId) {
    try {
      dispatch(loadingActions.setLoading(true));
      const response = await createCode(ticketId);
      if (response?.data?.phone) {
        setPhoneValue(response.data.phone);
      }

      setSelectedTicket(ticketId);
      nextStepHandler(2);
      dispatch(loadingActions.setLoading(false));
    } catch (error) {
      const message = t(error.message || "tryAgain");
      notification(message, {
        variant: "error",
      });
      dispatch(loadingActions.setLoading(false));
    }
  }

  function currentStepEffect() {
    if (currentStep === 1) {
      setSelectedTicket(null);
      setFoundTickets([]);
    }
  }

  function nextStepHandler(stepsToSkip = 1) {
    setCurrentStep(currentStep + stepsToSkip);
  }

  function previousStepHandler() {
    if (currentStep === 3 && hasParams && isTicketParam) {
      setPhoneValue("");
      navigate(localStorage.getItem("redirectOnLogout"));
    }
    if (currentStep === 3 && (!foundTickets || foundTickets.length === 0)) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  }
};
