import { LinearProgress, linearProgressClasses } from "@mui/material";
import React from "react";
import styled from "styled-components";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#90CC84" : "#308fe8",
  },
}));

export const ProgressBar = ({ value = 0, width }) => {
  return (
    <BorderLinearProgress
      style={{ width }}
      variant="determinate"
      value={value}
    />
  );
};
