import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Divider } from "@mui/material";

import { actions } from "../../redux/slices/loadingSlice";
import { unformatPhoneValue } from "../../utils/phone";
import { UserIcon } from "../../assets/icons";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";

import "./selectTicket.less";
import useAuthParams from "../../hooks/useAuthParams";

export const SelectTicket = ({
  nextStepHandler,
  tickets,
  setSelectedTicket,
  selectedTicket,
  phoneNumber,
}) => {
  const { createCode, createCodeByPhone } = useAuth();
  const { enqueueSnackbar: notification } = useSnackbar();
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loadingReducer);
  const { isTypeParam } = useAuthParams();

  const textVersion = tickets?.length > 1 ? 1 : 2;

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-2 h-full">
        <h2 className="title">{t(`phoneTitle${textVersion}`)}</h2>
        <span className="text-xs">{t(`phoneDescription${textVersion}`)}</span>
        <div className="flex flex-col gap-2 pr-1 overflow-y-auto flex-auto h-0">
          {tickets?.map((ticket) => (
            <div
              onClick={() => handleSelectTicket(ticket)}
              key={ticket?._id}
              className={`flex gap-1 flex-col justify-between p-4 bg-gray rounded-xl cursor-pointer ${
                selectedTicket === ticket?._id &&
                "border-solid border border-secondary"
              }`}
            >
              <div className="flex gap-2 items-center">
                <UserIcon />
                <span className="text-base font-bold">
                  {ticket?.applicant?.fullName}
                </span>
              </div>
              {ticket?.applicant?.ssn && (
                <div>SSN: XXX-XX-{ticket?.applicant?.ssn?.slice(-4)}</div>
              )}
              {ticket?.type && (
                <div>
                  {t("applicantType")}: {ticket?.type}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="mt-auto flex flex-col gap-4 sticky bottom-0 bg-white pb-2">
          {isTypeParam && (
            <>
              <div className="mt-2 mb-2">
                <Divider>{t("or")}</Divider>
              </div>
              <div
                onClick={() => handleSelectTicket(null)}
                className="text-secondary cursor-pointer w-max font-bold"
              >
                {t(`phoneNone${textVersion}`)}
              </div>
            </>
          )}
          <Button
            size="medium"
            variant="contained"
            color="secondary"
            className="w-full"
            disabled={loading || !selectedTicket}
            onClick={() => getCodeAndContinue()}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );

  async function handleSelectTicket(ticket = null) {
    setSelectedTicket(ticket?._id);
    if (!ticket) {
      if (loading) {
        return;
      }

      dispatch(actions.setLoading(true));
      const formattedPhone = "+1" + unformatPhoneValue(phoneNumber);
      await createCodeByPhone(formattedPhone);
      nextStepHandler();
      dispatch(actions.setLoading(false));
    }
  }

  async function getCodeAndContinue() {
    try {
      dispatch(actions.setLoading(true));
      await createCode(selectedTicket);
      nextStepHandler();
      dispatch(actions.setLoading(false));
    } catch (error) {
      const message = t(error.message || "tryAgain");
      notification(message, {
        variant: "error",
      });
      dispatch(actions.setLoading(false));
    }
  }
};
