import axios from "axios";
import { configure } from "axios-hooks";
import { handleUnauthenticated } from "./interceptors";

const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
});

export const APP_ENV =
  process.env.REACT_APP_ENV || process.env.NODE_ENV || "development";
const STATIC_DOMAIN_PART =
  APP_ENV === "stage"
    ? ".staging.application.arla.ai"
    : APP_ENV === "dev"
    ? ".dev.application.arla.ai"
    : ".application.arla.ai";

const CLIENT_NAME = getClientName();

const WEBSOCKET_NAME =
  APP_ENV === "development" || APP_ENV === "local"
    ? process.env.REACT_APP_WEBSOCKET_NAME
    : CLIENT_NAME;

axiosInstance.interceptors.request.use(
  function (request) {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    request.baseURL =
      process.env.REACT_APP_ENV !== "local"
        ? `https://${CLIENT_NAME}.${process.env.REACT_APP_BASE_URL}/`
        : `http://${process.env.REACT_APP_BASE_URL}/`;
    return request;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  handleUnauthenticated
);

export function getClientName() {
  const clientHostname = window.location.hostname;
  const clientName = clientHostname.slice(0, -STATIC_DOMAIN_PART.length);
  return clientName;
}

function getGatewayUrl(apiPrefix) {
  if (APP_ENV === "dev" && CLIENT_NAME !== "dev") {
    return `https://dev.${apiPrefix}${STATIC_DOMAIN_PART}`;
  }
  return `https://${CLIENT_NAME}.${apiPrefix}${STATIC_DOMAIN_PART}`;
}

function getSocketGateway(API_GATEWAY) {
  let SOCKET_GATEWAY = {};
  for (let key in API_GATEWAY) {
    SOCKET_GATEWAY[key] = `${API_GATEWAY[key].replace(
      "http",
      "ws"
    )}/${WEBSOCKET_NAME}`.replace(".application", "");
  }
  return SOCKET_GATEWAY;
}

export function getApiGateway(env) {
  let apiGateway;

  switch (env) {
    case "dev":
    case "stage":
    case "production":
      apiGateway = {
        mainAPI: getGatewayUrl("api"),
        botAPI: getGatewayUrl("bot"),
        commsAPI: getGatewayUrl("comms"),
      };
      break;
    default:
      apiGateway = {
        mainAPI: "http://localhost:1337",
        botAPI: "http://localhost:1338",
        commsAPI: "http://localhost:1339",
      };
  }

  return apiGateway;
}

const API_GATEWAY = getApiGateway(APP_ENV);

export const SOCKET_GATEWAY = getSocketGateway(API_GATEWAY);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("ticketId");
    }
    return Promise.reject(
      error?.response?.data?.messageUploader ||
        error?.response?.data?.message ||
        "Something went wrong"
    );
  }
);

configure({ cache: false, axios: axiosInstance });

export default axiosInstance;
