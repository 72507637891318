import React from "react";

const CaretRight = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 6 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.340629 0.952412C0.550292 0.772701 0.865942 0.796982 1.04565 1.00664L5.04565 5.67331C5.20615 5.86056 5.20615 6.13686 5.04565 6.3241L1.04565 10.9908C0.865942 11.2004 0.550292 11.2247 0.340629 11.045C0.130966 10.8653 0.106685 10.5496 0.286396 10.34L4.00749 5.99871L0.286396 1.65744C0.106685 1.44777 0.130966 1.13212 0.340629 0.952412Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretRight;
