import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import useAuthParams from "../../hooks/useAuthParams";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const params = useParams();
  const isAuthenticated = localStorage.getItem("token");
  const ticket = localStorage.getItem("ticketId");
  const applicantType = localStorage.getItem("applicantType");
  const { value, isTypeParam } = useAuthParams();

  const applicantTypeFromParams = isTypeParam ? value : undefined;

  if (!isAuthenticated) {
    return (
      <Navigate
        to={applicantTypeFromParams ? `/type=${applicantTypeFromParams}` : "/"}
      />
    );
  }

  if (isAuthenticated) {
    if (ticket && !params.ticketId) {
      return <Navigate to={`ticket/${ticket}`} />;
    }
    if (applicantType && !params.applicantType) {
      return <Navigate to={`type/${applicantType}`} />;
    }
  } else {
    if (isAuthenticated && !ticket && params.ticketId) {
      return <Navigate to="/" />;
    }
  }

  return <>{children}</>;
}

export default AuthGuard;
