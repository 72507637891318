import React from "react";

const User = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 0.832031C7.1608 0.832031 6.35535 1.16566 5.76148 1.75953C5.16761 2.35339 4.83398 3.15885 4.83398 3.9987C4.83398 4.83855 5.16761 5.644 5.76148 6.23787C6.35535 6.83173 7.1608 7.16536 8.00065 7.16536C8.8405 7.16536 9.64596 6.83173 10.2398 6.23787C10.8337 5.644 11.1673 4.83855 11.1673 3.9987C11.1673 3.15885 10.8337 2.35339 10.2398 1.75953C9.64596 1.16566 8.8405 0.832031 8.00065 0.832031ZM5.83398 3.9987C5.83398 3.42406 6.06226 2.87296 6.46859 2.46663C6.87492 2.0603 7.42602 1.83203 8.00065 1.83203C8.57529 1.83203 9.12639 2.0603 9.53272 2.46663C9.93904 2.87296 10.1673 3.42406 10.1673 3.9987C10.1673 4.57333 9.93904 5.12443 9.53272 5.53076C9.12639 5.93709 8.57529 6.16536 8.00065 6.16536C7.42602 6.16536 6.87492 5.93709 6.46859 5.53076C6.06226 5.12443 5.83398 4.57333 5.83398 3.9987ZM8.00065 8.16536C6.64065 8.16536 5.38598 8.4787 4.45265 9.01203C3.53398 9.53736 2.83398 10.3387 2.83398 11.332C2.83398 12.3254 3.53398 13.1267 4.45265 13.652C5.38598 14.1854 6.64132 14.4987 8.00065 14.4987C9.36065 14.4987 10.6153 14.1854 11.5487 13.652C12.4673 13.1267 13.1673 12.3254 13.1673 11.332C13.1673 10.3387 12.4673 9.53736 11.5487 9.01203C10.6153 8.4787 9.35998 8.16536 8.00065 8.16536ZM3.83398 11.332C3.83398 10.852 4.17865 10.3207 4.94865 9.8807C5.70465 9.4487 6.78265 9.16537 8.00065 9.16537C9.21865 9.16537 10.2967 9.4487 11.0527 9.8807C11.8227 10.3207 12.1673 10.8527 12.1673 11.332C12.1673 11.812 11.8227 12.3434 11.0527 12.7834C10.2967 13.2154 9.21865 13.4987 8.00065 13.4987C6.78265 13.4987 5.70465 13.2154 4.94865 12.7834C4.17865 12.3434 3.83398 11.812 3.83398 11.332Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default User;
