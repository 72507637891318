import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import { reducer as ticketReducer } from "./slices/ticketDetailSlice";
import { reducer as loadingReducer } from "./slices/loadingSlice";
import { reducer as languageReducer } from "./slices/languageSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    ticket: ticketReducer,
    loadingReducer,
    languageReducer,
  },
});
