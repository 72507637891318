import React from "react";

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="40"
      viewBox="0 0 180 40"
      fill="none"
    >
      <g clipPath="url(#clip0_8766_59346)">
        <path
          d="M96.0554 35.8911H92.068C91.4895 35.8911 91.1455 35.5426 90.958 35.0515L88.9721 29.0959H78.871L76.8852 35.0515C76.6976 35.5901 76.4005 35.8911 75.775 35.8911H71.7877C71.2561 35.8911 70.9121 35.495 70.9121 35.0041C70.9121 34.5604 71.1623 33.9269 71.4437 33.04L80.9663 5.25808C81.2165 4.62452 81.451 4.37109 81.9827 4.37109H85.8605C86.3921 4.37109 86.6423 4.62452 86.8768 5.25808L96.3994 33.04C96.6965 33.9269 96.9311 34.5604 96.9311 35.0041C96.9311 35.495 96.5871 35.8911 96.0554 35.8911ZM85.5321 18.2303C85.1881 17.1533 84.2187 13.8112 84.2187 13.4152C84.2187 13.0668 84.1249 12.9242 83.9216 12.9242C83.7339 12.9242 83.6245 13.0668 83.6245 13.4152C83.6245 13.8112 82.655 17.1533 82.311 18.2303L80.2783 24.3284H87.5649L85.5321 18.2303Z"
          fill="#00142E"
        />
        <path
          d="M125.296 35.8912H120.683C119.135 35.8912 118.447 35.0992 117.18 32.7867L112.662 24.6295C112.271 23.9485 111.989 23.6475 111.301 23.6475H108.721V34.9567C108.721 35.4953 108.33 35.8912 107.799 35.8912H103.53C102.998 35.8912 102.607 35.4953 102.607 34.9567V5.28998C102.607 4.75145 102.998 4.35547 103.53 4.35547H114.303C120.23 4.35547 123.716 8.18854 123.716 13.8906C123.716 17.9296 121.684 21.8577 117.399 22.9349C118.463 23.4258 119.151 24.4078 120.167 26.1344L124.248 33.0243C124.686 33.7687 124.983 33.9113 125.515 34.1489C125.859 34.2914 126.14 34.4974 126.14 34.9883C126.172 35.5428 125.827 35.8912 125.296 35.8912ZM112.333 9.28144H108.737V18.7691H112.177C115.726 18.7691 117.571 17.4861 117.571 14.0015C117.571 10.5644 115.82 9.28144 112.333 9.28144Z"
          fill="#00142E"
        />
        <path
          d="M148.407 35.8912H132.723C132.192 35.8912 131.801 35.4953 131.801 34.9567V5.28998C131.801 4.75145 132.192 4.35547 132.723 4.35547H136.992C137.524 4.35547 137.915 4.75145 137.915 5.28998V30.4742H148.407C148.938 30.4742 149.329 30.8702 149.329 31.4087V34.9567C149.329 35.4953 148.938 35.8912 148.407 35.8912Z"
          fill="#00142E"
        />
        <path
          d="M178.991 35.891H175.004C174.425 35.891 174.081 35.5425 173.894 35.0516L171.908 29.096H161.807L159.821 35.0516C159.633 35.59 159.336 35.891 158.711 35.891H154.723C154.192 35.891 153.848 35.495 153.848 35.004C153.848 34.5605 154.098 33.927 154.379 33.0399L163.902 5.25808C164.152 4.62452 164.387 4.37109 164.918 4.37109H168.796C169.328 4.37109 169.578 4.62452 169.812 5.25808L179.335 33.0399C179.632 33.927 179.867 34.5605 179.867 35.004C179.867 35.495 179.523 35.891 178.991 35.891ZM168.468 18.2303C168.124 17.1533 167.154 13.8112 167.154 13.4152C167.154 13.0668 167.06 12.9242 166.857 12.9242C166.67 12.9242 166.56 13.0668 166.56 13.4152C166.56 13.8112 165.591 17.1533 165.247 18.2303L163.214 24.3284H170.5L168.468 18.2303Z"
          fill="#00142E"
        />
      </g>
      <g clipPath="url(#clip1_8766_59346)">
        <path
          d="M59.5549 20C59.5549 8.94905 49.7369 0 37.6363 0C32.4043 0 27.6136 1.66847 23.8457 4.45285C25.9557 4.64787 27.9582 5.16791 29.7883 5.95881C32.1567 4.9404 34.8266 4.36619 37.6472 4.36619C47.4869 4.36619 55.4641 11.3651 55.4641 20C55.4641 28.6348 47.4869 35.6337 37.6472 35.6337C27.8074 35.6337 19.8302 28.6348 19.8302 20C19.8302 15.4171 22.0694 11.3001 25.6543 8.43984C24.9546 8.27733 24.244 8.15815 23.5227 8.09314C22.9953 8.03897 22.4677 8.0173 21.9403 8.0173C21.2943 8.0173 20.6376 8.06064 20.0024 8.12565C17.3219 11.4409 15.7285 15.5471 15.7285 19.989C15.7285 31.0292 25.5466 39.989 37.6472 39.989C42.8146 39.989 47.5729 38.353 51.3193 35.6228L58.2523 37.5297L56.3361 30.4442C58.3815 27.3996 59.5549 23.8243 59.5549 20Z"
          fill="url(#paint0_linear_8766_59346)"
        />
        <path
          d="M37.6363 0C32.4043 0 27.6137 1.66846 23.8457 4.45285C25.9557 4.64787 27.9581 5.16791 29.7883 5.95881C32.1567 4.9404 34.8266 4.36619 37.647 4.36619C44.3217 4.36619 50.1352 7.58394 53.1926 12.351L58.2953 13.2502C55.238 5.52544 47.1423 0 37.6363 0Z"
          fill="url(#paint1_linear_8766_59346)"
        />
        <path
          d="M23.8347 35.5364C23.2103 35.5907 22.5644 35.6232 21.9184 35.6232C20.6051 35.6232 19.3347 35.4931 18.1074 35.2656C18.6027 35.8074 19.1301 36.3381 19.69 36.8474C20.9711 38.0174 22.3598 39.0359 23.8347 39.9134C25.9124 39.751 27.9041 39.3176 29.7773 38.6675C27.6134 37.9201 25.611 36.8474 23.8347 35.5364Z"
          fill="#73263B"
        />
        <path
          d="M39.5741 8.13648C38.4652 6.76054 37.1733 5.52544 35.7199 4.45285C33.9436 3.14191 31.9522 2.08016 29.7775 1.32177C27.9042 0.67172 25.9126 0.238353 23.8349 0.0758394C23.2105 0.0216685 22.5646 0 21.9186 0C9.81816 0 0 8.94904 0 19.9999C0 23.8244 1.18421 27.3997 3.22966 30.4441L1.31339 37.5404L8.24638 35.6336C11.9928 38.3639 16.7512 39.9999 21.9186 39.9999C22.5646 39.9999 23.1996 39.9781 23.8349 39.9241C22.36 39.0465 20.9713 38.0173 19.6902 36.858C19.1303 36.3488 18.6136 35.8288 18.1076 35.2761C10.0981 33.7486 4.10166 27.4863 4.10166 19.9999C4.10166 12.5135 10.0981 6.26217 18.1076 4.7237C19.3349 4.48536 20.616 4.36617 21.9186 4.36617C22.5646 4.36617 23.1996 4.39868 23.8349 4.45285C25.9449 4.64788 27.9473 5.16791 29.7775 5.95881C31.2953 6.60887 32.6842 7.44309 33.9222 8.43984C37.4963 11.3001 39.7463 15.4171 39.7463 19.9999C39.7463 24.5829 37.4963 28.6997 33.9222 31.5601C35.1063 31.8308 36.3552 31.9717 37.6362 31.9717C38.2929 31.9717 38.9389 31.9284 39.5741 31.8634C42.2546 28.5481 43.8479 24.442 43.8479 19.9999C43.8479 15.5579 42.2546 11.4517 39.5741 8.13648Z"
          fill="url(#paint2_linear_8766_59346)"
        />
        <path
          d="M35.7208 4.45123C36.3453 4.39706 36.9911 4.36456 37.6371 4.36456C38.9397 4.36456 40.2208 4.49457 41.4481 4.72209C40.3716 3.552 39.1767 2.46857 37.874 1.50433C37.185 0.995125 36.4637 0.518421 35.7208 0.0742188C33.6431 0.236732 31.6514 0.670099 29.7891 1.32015C31.9421 2.07854 33.9446 3.1403 35.7208 4.45123Z"
          fill="#73263B"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8766_59346"
          x1="59.3161"
          y1="35.0845"
          x2="26.0997"
          y2="12.2655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8195B" />
          <stop offset="0.3232" stopColor="#BF266D" />
          <stop offset="0.7114" stopColor="#D85490" />
          <stop offset="1" stopColor="#BF266D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8766_59346"
          x1="62.3659"
          y1="21.6575"
          x2="26.9994"
          y2="-2.63823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8195B" stopOpacity="0" />
          <stop offset="0.5378" stopColor="#A8195B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8766_59346"
          x1="49.3609"
          y1="19.9982"
          x2="-34.4857"
          y2="19.9982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8195B" />
          <stop offset="0.3232" stopColor="#BF266D" />
          <stop offset="0.7114" stopColor="#D85490" />
          <stop offset="1" stopColor="#BF266D" />
        </linearGradient>
        <clipPath id="clip0_8766_59346">
          <rect
            width="109.255"
            height="31.5942"
            fill="white"
            transform="translate(70.7441 4.34766)"
          />
        </clipPath>
        <clipPath id="clip1_8766_59346">
          <rect width="59.6197" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
