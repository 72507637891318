export const POPULAR_LANGUAGES = {
  es: {
    name: "Spanish",
    nativeName: "Español",
    flag: "ES",
  },
  en: {
    name: "English",
    nativeName: "English",
    flag: "US",
  },
  zh: {
    name: "Chinese",
    nativeName: "中国人",
    flag: "CN",
  },
  ru: {
    name: "Russian",
    nativeName: "Русский",
    flag: "RU",
  },
  bn: {
    name: "Bengali",
    nativeName: "বাংলা",
    flag: "BD",
  },
};
