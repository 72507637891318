import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
// import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SnackbarProvider } from "notistack";
// import StylesProvider from "@mui/styles/StylesProvider";
// import jssPreset from "@mui/styles/jssPreset";

// import "./i18n";
import { AuthProvider } from "./contexts/JWTContext";
import { Loading } from "./components/shared/Loading";
import { store } from "./redux/store";
import createTheme from "./theme";
import GlobalStyle from "./components/GlobalStyle";
import routes from "./routes";
import useTheme from "./hooks/useTheme";

// const jss = create({
//   ...jssPreset(),
//   insertionPoint: document.getElementById("jss-insertion-point"),
// });

import "./app.less";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s" defaultTitle="">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Provider store={store}>
        {/*<StylesProvider jss={jss}>*/}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <SnackbarProvider maxSnack={5}>
                  <GlobalStyle />
                  <AuthProvider>
                    <div id="root">
                      <main className="h-full w-full">
                        {content}
                        <Loading />
                      </main>
                    </div>
                  </AuthProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
        {/*</StylesProvider>*/}
      </Provider>
    </HelmetProvider>
  );
}

export default App;
