import { makeStyles } from "@mui/styles";
import { stepConnectorClasses } from "@mui/material/StepConnector";
const useStyles = makeStyles((theme) => {
  return {
    caption: {
      display: "box",
      lineClamp: 2,
      boxOrient: "vertical",
      overflow: "hidden",
      width: "100%",
    },
    container: {
      position: "relative",
      height: "100%",
      padding: 0,
      zIndex: 0,
    },
    error: {
      color: "#A61919",
      fontWeight: 400,
      fontSize: "16px",
    },
    alternativeStepper: {
      width: "100%",
      justifyContent: "center",
      [`& .MuiStep-alternativeLabel`]: {
        display: "flex",
        justifyContent: "center",
      },
      [`& .${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 12px)",
        right: "calc(50% + 12px)",
      },
      [`& .${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: "#31A619",
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#A61919",
        borderTopWidth: 2,
        borderRadius: 1,
      },
    },
    descriptionMessage: {
      textAlign: "center",
      fontSize: "20px",
      fontWeight: 700,
      margin: 0,
    },
    containerBox: {
      paddingTop: "24px",
      height: "100%",
    },
    arrowContainer: {
      maxWidth: "240px",
      width: "100%",
      maxHeight: "114px",
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
      [`& .MuiStepLabel-label`]: {
        marginTop: "0 ",
        display: "flex",
        justifyContent: "center",
      },
    },
    arrows: {
      top: 0,
      left: 0,
      width: "260px",
      height: "83px",
      display: "flex",
      position: "absolute",
      justifyContent: "center",
    },
    stepContent: {
      minWidth: "80px",
      maxWidth: "160px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    stepContentRow: {
      gap: "4px",
      display: "flex",
      fontSize: "11px",
      color: "#0D0D0D",
    },
    rowTitle: {
      fontSize: " 14px",
      fontWeight: 600,
    },
    rowIncomplete: {
      color: "#a61919",
      fontWeight: 700,
    },
    uploadLabel: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
      flexDirection: "column",
      justifyContent: "center",
    },
    logo: {
      width: theme.spacing(12),
    },
    reDownload: {
      transform: "rotate(180deg)",
    },
    swipeView: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      [`& .react-swipeable-view-container`]: {
        width: "100%",
        [`& > div`]: {
          overflow: "hidden !important",
        },
        [`& .MuiBox-root`]: {
          padding: 0,
        },
      },
    },
    errorPage: {
      gap: "20px",
      width: "100%",
      display: "flex",
      overflow: "auto",
      textAlign: "center",
      alignItems: "center",
      borderRadius: "unset",
      flexDirection: "column",
      justifyContent: "center",
      height: "calc(100% - 100px)",
      "& > *": {
        maxWidth: "90%",
      },
    },
    selectItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      backgroundColor: "#F5F7FA",
      marginBlock: "10px",
      padding: "16px",
      borderRadius: "13px",
      "&.completed": {
        backgroundColor: "#EEFFEB",
        color: "#31A619",
      },
      "&.inprogress": {
        backgroundColor: "#FFF6EB",
        color: "#A66919",
      },
    },
    containerActions: {
      gap: "20px",
      display: "flex",
      marginTop: "auto",
      flexDirection: "row",
      justifyContent: "center",
    },
    dialogContent: {
      gap: 8,
      paddingTop: 4,
      display: "grid",
      minWidth: "300px",
      minHeight: "300px",
      alignItems: "center",
      justifyContent: "center",
      gridTemplateRows: "1fr auto",
    },
    fileName: {
      maxWidth: "calc(100% - 24px)",
    },
  };
});

export default useStyles;
