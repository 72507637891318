import React from "react";

const RotateRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M21 13.9219C20.7867 15.5415 20.1345 17.0727 19.1134 18.3514C18.0924 19.63 16.741 20.6079 15.2041 21.18C13.6673 21.7521 12.003 21.897 10.3897 21.599C8.77644 21.3011 7.27509 20.5715 6.04665 19.4887C4.81821 18.4058 3.90903 17.0105 3.41662 15.4523C2.92421 13.8941 2.86714 12.2319 3.25154 10.6439C3.63593 9.05586 4.44729 7.60198 5.5986 6.43814C6.7499 5.2743 8.19772 4.44442 9.78678 4.0375C14.1919 2.91274 18.7517 5.17013 20.4351 9.37674"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3C21.4142 3 21.75 3.33579 21.75 3.75V9.75C21.75 10.1642 21.4142 10.5 21 10.5H15C14.5858 10.5 14.25 10.1642 14.25 9.75C14.25 9.33579 14.5858 9 15 9H20.25V3.75C20.25 3.33579 20.5858 3 21 3Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default RotateRight;
