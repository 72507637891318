import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { getProgress } from "../../utils/ticket";

const initialState = {
  ticket: null,
  config: null,
  stages: null,
  applicantTypes: [],
  progress: null,
  loading: false,
  error: false,
};

export const getTicket = createAsyncThunk("tickets/get", async ({ id }) => {
  try {
    if (id) {
      const [ticket, config] = await Promise.all([
        axios.get(`/tickets/ats/${id}`),
        axios.get(`/tickets/ats/getDocumentsConfigs/${id}`),
      ]);
      const configs = config?.data?.config;

      const filterResponse = {
        ticket: { ...ticket?.data?.ticket },
        config: getConfigItemsToShow(configs?.documentReader),
        stages: configs?.stages,
      };

      function getItemsToShow(data) {
        return data?.filter((item) => item.showInUploader);
      }

      function getConfigItemsToShow(data) {
        const items = getItemsToShow(data).map((item) => {
          return { ...item, types: getItemsToShow(item.types) };
        });
        return items;
      }

      return Promise.resolve(filterResponse);
    }
    return Promise.reject("Undefined ticketID");
  } catch (err) {
    return Promise.reject(err);
  }
});

export const getConfig = createAsyncThunk("config/get", async ({ type }) => {
  try {
    if (type) {
      const config = await axios.get(
        `/tickets/ats/getDocumentsConfigs/type/${type}`
      );
      const configs = config?.data?.config;

      const filterResponse = {
        config: getConfigItemsToShow(configs?.documentReader),
        stages: configs?.stages,
      };

      function getItemsToShow(data) {
        return data?.filter((item) => item.showInUploader);
      }

      function getConfigItemsToShow(data) {
        const items = getItemsToShow(data).map((item) => {
          return { ...item, types: getItemsToShow(item.types) };
        });
        return items;
      }

      return Promise.resolve(filterResponse);
    }
    return Promise.reject("Undefined type");
  } catch (err) {
    return Promise.reject(err);
  }
});

const ticketDetails = createSlice({
  name: "ticketDetails",
  initialState,
  reducers: {
    resetTicket: (state) => {
      state.ticket = null;
      state.config = null;
      state.progress = null;
    },
    setApplicantTypes: (state, { payload }) => {
      state.applicantTypes = payload;
    },
  },
  extraReducers: {
    [getTicket.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
    [getTicket.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.ticket = payload?.ticket;
      state.config = payload?.config;
      state.stages = payload?.stages;
      const progress = getProgress(
        payload?.stages,
        payload?.config,
        payload?.ticket
      );
      state.progress = progress;
    },
    [getTicket.pending]: (state) => {
      state.loading = true;
    },
    [getConfig.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
    [getConfig.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.config = payload?.config;
      state.stages = payload?.stages;
      const progress = getProgress(payload?.stages, payload?.config, null);
      state.progress = progress;
    },
    [getConfig.pending]: (state) => {
      state.loading = true;
    },
  },
});
export const { reducer, actions, state, getInitialState } = ticketDetails;
