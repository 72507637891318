import React from "react";

import { LogoIcon } from "../../assets/icons";
import { LanguageSelection } from "../shared/LanguageSelection";

import "./header.less";

export const Header = () => {
  return (
    <header className="background flex justify-center p-16 relative">
      <div className="absolute top-0 right-4 mt-2">
        <LanguageSelection color="#0D0D0D" />
      </div>
      <LogoIcon />
    </header>
  );
};
