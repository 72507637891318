import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

import useAuth from "../../hooks/useAuth";
import { ReactComponent as Logo } from "../../vendor/arla-logo.svg";
import { LogoutIcon } from "../../assets/icons";
import { LanguageSelection } from "../../components/shared/LanguageSelection";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    color: "#fff",
    position: "relative",
    backgroundColor: "#00142E",
  },
  logoutBtn: {
    color: "white",
    fontWeight: 400,
  },
}));

export default function Header() {
  const { signOut, isAuthenticated } = useAuth();
  const [t] = useTranslation("global");
  const params = useParams();

  const classes = useStyles();

  return (
    <>
      <div className={classes.card}>
        <Stack
          direction="column"
          padding="16px 24px"
          className=" max-w-screen-xl mx-auto"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing="24px"
          >
            <Logo width={85} height={19} />
            <div className="flex flex-row items-center gap-4">
              <LanguageSelection color="white" />
              {isAuthenticated && (
                <Button
                  className={classes.logoutBtn}
                  variant="text"
                  startIcon={<LogoutIcon />}
                  onClick={handleSignOut}
                >
                  {t("logOut")}
                </Button>
              )}
            </div>
          </Stack>
        </Stack>
      </div>
    </>
  );

  async function handleSignOut() {
    const applicantType = params.applicantType;
    await signOut(applicantType);
  }
}
