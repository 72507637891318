import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";

import { setAppTitle } from "./utils/setAppTitle";
import { ThemeProvider } from "./contexts/ThemeContext";
import App from "./App";
import i18n from "./i18n";
import reportWebVitals from "./utils/reportWebVitals";

setAppTitle();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
  environment: process.env.REACT_APP_ENV,
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <I18nextProvider i18n={i18n}>
        <React.Suspense fallback="loading">
          <App />
        </React.Suspense>
      </I18nextProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
