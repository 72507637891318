import React from "react";

const RotateLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M3.5 13.9219C3.71334 15.5415 4.36552 17.0727 5.38657 18.3514C6.40763 19.63 7.75905 20.6079 9.2959 21.18C10.8327 21.7521 12.497 21.897 14.1103 21.599C15.7236 21.3011 17.2249 20.5715 18.4534 19.4887C19.6818 18.4058 20.591 17.0105 21.0834 15.4523C21.5758 13.8941 21.6329 12.2319 21.2485 10.6439C20.8641 9.05586 20.0527 7.60198 18.9014 6.43814C17.7501 5.2743 16.3023 4.44442 14.7132 4.0375C10.3081 2.91274 5.74829 5.17013 4.0649 9.37674"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3C3.08579 3 2.75 3.33579 2.75 3.75V9.75C2.75 10.1642 3.08579 10.5 3.5 10.5H9.5C9.91421 10.5 10.25 10.1642 10.25 9.75C10.25 9.33579 9.91421 9 9.5 9H4.25V3.75C4.25 3.33579 3.91421 3 3.5 3Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default RotateLeft;
