import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import axios from "../utils/axios";
import { /*isValidToken,*/ setSession } from "../utils/jwt";
import { useSnackbar } from "notistack";
import { getTicket } from "../redux/slices/ticketDetailSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { socket } from "../utils/socketHandler";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_IN_WITH_CODE = "SIGN_IN_WITH_CODE";
const SET_TICKET = "SET_TICKET";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  ticketId: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        ticketId: action.payload.ticketId,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        ticketId: null,
      };
    case SIGN_IN_WITH_CODE:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        ticketId: action.payload.ticketId,
      };
    case SET_TICKET:
      return {
        ...state,
        ticketId: action.payload.ticketId,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        ticketId: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const dispatchTicket = useDispatch();
  const { enqueueSnackbar: notification } = useSnackbar();
  const [t] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("token");
        const ticketId = window.localStorage.getItem("ticketId");

        if (ticketId) {
          dispatchTicket(getTicket({ id: ticketId }));
        }

        if (accessToken) {
          setSession(accessToken);

          const user = jwtDecode(accessToken);

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              ticketId,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [dispatchTicket]);

  const signIn = async (email, password) => {
    const response = await axios.post("/users/login", {
      email,
      password,
    });
    const { token } = response.data;
    const user = jwtDecode(token);

    setSession(token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async (applicantType = null) => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
    const redirectOnLogout = localStorage.getItem("redirectOnLogout");
    socket.disconnect();
    navigate(redirectOnLogout ? redirectOnLogout : `/`);
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { token } = response.data;
    const user = jwtDecode(token);

    window.localStorage.setItem("token", token);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  const createCode = async (ticketId) => {
    return axios
      .post(`/tickets/ats/code/${ticketId}`)
      .then((response) => {
        const translatableMessage = response?.data?.messageUploader;
        if (translatableMessage) {
          notification(t(translatableMessage), {
            variant: "success",
          });
        } else {
          const message = response?.data?.message;
          notification(message, {
            variant: "success",
          });
        }

        return response;
      })
      .catch((err) => notification(t(err), { variant: "error" }));
  };

  const createCodeByPhone = async (phoneNumber) => {
    const data = {
      phone: phoneNumber,
    };
    return axios
      .post(`/tickets/ats/code`, data)
      .then((response) => {
        const translatableMessage = response?.data?.messageUploader;
        if (translatableMessage) {
          notification(t(translatableMessage), {
            variant: "success",
          });
        } else {
          const message = response?.data?.message;
          notification(message, {
            variant: "success",
          });
        }
      })
      .catch((err) => notification(t(err), { variant: "error" }));
  };

  const getTokenByCodeAndTicket = async (code, ticketId) => {
    const response = await axios.post(`/tickets/ats/sso/${ticketId}/${code}`);
    const { token } = response.data;
    return token;
  };

  const getTokenByCodeAndPhone = async (code, phoneNumber) => {
    const response = await axios.post(
      `/tickets/ats/sso/phone/${phoneNumber}/${code}`
    );
    const { token } = response.data;
    return token;
  };

  const authenticateWithTokenAndTicketId = (token, ticketId, applicantType) => {
    const user = jwtDecode(token);
    setSession(token, ticketId);
    socket.auth = { token };
    socket.connect();

    if (applicantType && !ticketId) {
      localStorage.setItem("applicantType", applicantType);
    }

    navigate(ticketId ? `/ticket/${ticketId}` : `/type/${applicantType}`);

    dispatch({
      type: SIGN_IN_WITH_CODE,
      payload: {
        user,
        ticketId,
      },
    });
  };

  const setAuthTicket = (ticketId) => {
    localStorage.setItem("ticketId", ticketId);
    dispatch({
      type: SET_TICKET,
      payload: {
        ticketId,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        createCode,
        createCodeByPhone,
        getTokenByCodeAndTicket,
        getTokenByCodeAndPhone,
        authenticateWithTokenAndTicketId,
        setAuthTicket,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
