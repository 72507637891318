import React from "react";

import { clientLogoMap } from "../../shared/scripts/clientLogoMap";
import { getClientName } from "../../utils/axios";

const client = getClientName();

export const ClientLogo = () => {
  return clientLogoMap[client] ? (
    <img
      style={{ maxWidth: "260px", maxHeight: "160px" }}
      src={clientLogoMap[client]}
      alt="Logo"
    />
  ) : null;
};
