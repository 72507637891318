import * as React from "react";

const Orientation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || "currentColor"}
      fillRule="evenodd"
      d="M8.945 1.25h6.11c1.367 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337v8.11c0 1.367 0 2.47-.116 3.337-.122.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116h-6.11l-.899-.001a.794.794 0 0 1-.1 0c-.918-.007-1.693-.029-2.338-.115-.9-.122-1.658-.38-2.26-.982-.602-.602-.86-1.36-.981-2.26-.097-.715-.113-1.59-.116-2.642H2a.75.75 0 1 1 0-1.5h.25v-2.5H2a.75.75 0 1 1 0-1.5h.25v-2.5H2a.75.75 0 0 1 0-1.5h.25c.004-1.052.02-1.927.117-2.642.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117ZM3.75 8.75H4a.75.75 0 0 0 0-1.5h-.25c.004-1.046.02-1.826.103-2.442.099-.734.28-1.122.556-1.399.277-.277.665-.457 1.4-.556.4-.054.872-.08 1.441-.092V21.24a13.239 13.239 0 0 1-1.442-.092c-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.083-.615-.099-1.395-.102-2.441H4a.75.75 0 1 0 0-1.5h-.25v-2.5H4a.75.75 0 1 0 0-1.5h-.25V8.75Zm5 12.5H15c1.435 0 2.436-.002 3.192-.103.734-.099 1.122-.28 1.399-.556.277-.277.457-.665.556-1.4.101-.755.103-1.756.103-3.191V8c0-1.435-.002-2.437-.103-3.192-.099-.734-.28-1.122-.556-1.399-.277-.277-.665-.457-1.4-.556-.755-.101-1.756-.103-3.191-.103H8.75v18.5Zm2-14.75a.75.75 0 0 1 .75-.75h5a.75.75 0 1 1 0 1.5h-5a.75.75 0 0 1-.75-.75Zm0 3.5a.75.75 0 0 1 .75-.75h5a.75.75 0 1 1 0 1.5h-5a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Orientation;
