import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";

import useAuth from "../hooks/useAuth";
import {
  actions,
  getConfig,
  getTicket,
} from "../redux/slices/ticketDetailSlice";
import Header from "../pages/documents/Header";
import axios from "../utils/axios";

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  background: #fff;
  borderradius: unset;
  flex-direction: column;
`;

const Dashboard = () => {
  const { ticketId } = useAuth();
  const { progress, applicantTypes } = useSelector((state) => state.ticket);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(ticketEffect, [dispatch, ticketId]);
  useEffect(paramsEffect, [dispatch, params, applicantTypes]);

  return (
    <Root>
      <CssBaseline />
      <Header />
      {progress && <Outlet />}
    </Root>
  );

  function ticketEffect() {
    dispatch(getTicket({ id: ticketId }));
    return () => {
      dispatch(actions.resetTicket());
    };
  }

  function paramsEffect() {
    if (params.applicantType) {
      let allApplicantTypes = applicantTypes;
      async function getApplicantTypes() {
        if (applicantTypes?.length === 0) {
          const { data } = await axios({
            url: `/tickets/ats/main-flow-applicant-types`,
          });
          allApplicantTypes = data.applicantTypes;
          dispatch(actions.setApplicantTypes(data.applicantTypes));
        }
      }
      if (allApplicantTypes.length === 0) {
        getApplicantTypes();
      }

      const selectedApplicantType = allApplicantTypes.find(
        (type) => type.nameToShow === params.applicantType
      )?.name;
      if (selectedApplicantType) {
        dispatch(
          getConfig({ type: encodeURIComponent(selectedApplicantType) })
        );
      }
    }
  }
};

export default Dashboard;
