import React from "react";

const Phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.65535 3.02886C3.46668 2.2222 4.80268 2.36553 5.48202 3.27353L6.32335 4.3962C6.87668 5.13486 6.82735 6.16686 6.17068 6.81953L6.01202 6.9782C5.9941 7.04482 5.99227 7.11474 6.00669 7.1822C6.04869 7.4542 6.27602 8.0302 7.22802 8.97686C8.18002 9.92353 8.76002 10.1502 9.03602 10.1929C9.10549 10.2076 9.1775 10.2056 9.24602 10.1869L9.51802 9.9162C10.102 9.3362 10.998 9.22753 11.7207 9.6202L12.994 10.3135C14.0854 10.9055 14.3607 12.3882 13.4674 13.2769L12.52 14.2182C12.2214 14.5149 11.82 14.7622 11.3307 14.8082C10.124 14.9209 7.31269 14.7769 4.35735 11.8389C1.59935 9.0962 1.07002 6.7042 1.00268 5.52553C0.969351 4.92953 1.25068 4.42553 1.60935 4.06953L2.65535 3.02886ZM4.68202 3.87286C4.34402 3.42153 3.71468 3.38553 3.36002 3.7382L2.31335 4.7782C2.09335 4.99686 1.98802 5.2382 2.00135 5.46886C2.05468 6.40553 2.48135 8.56353 5.06269 11.1302C7.77068 13.8222 10.2714 13.9029 11.238 13.8122C11.4354 13.7942 11.6314 13.6915 11.8147 13.5095L12.7614 12.5675C13.1467 12.1849 13.062 11.4875 12.5167 11.1915L11.2434 10.4989C10.8914 10.3082 10.4794 10.3709 10.2234 10.6255L9.92002 10.9275L9.56668 10.5729C9.92002 10.9275 9.91935 10.9282 9.91868 10.9282L9.91802 10.9295L9.91602 10.9315L9.91135 10.9355L9.90135 10.9449C9.87301 10.9707 9.84269 10.9944 9.81068 11.0155C9.75735 11.0509 9.68668 11.0902 9.59802 11.1229C9.41802 11.1902 9.17935 11.2262 8.88468 11.1809C8.30668 11.0922 7.54068 10.6982 6.52269 9.6862C5.50535 8.6742 5.10802 7.91286 5.01868 7.33553C4.97268 7.04086 5.00935 6.8022 5.07735 6.6222C5.11506 6.52104 5.16862 6.42653 5.23602 6.3422L5.25735 6.31886L5.26668 6.30886L5.27069 6.30486L5.27269 6.30286L5.27402 6.30153L5.46602 6.11086C5.75135 5.8262 5.79135 5.35486 5.52269 4.99553L4.68202 3.87286Z"
        fill="#00142E"
      />
    </svg>
  );
};

export default Phone;
