export const formatPhoneValue = (phoneString) => {
  //Filter only numbers from the input
  let cleaned = ("" + phoneString).replace(/\D/g, "");

  //Remove country code if present
  if (cleaned.length > 10) {
    cleaned = cleaned.substring(1);
  }

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3];
  else return cleaned;
};

export function unformatPhoneValue(value) {
  const unformattedPhoneValue = value.replace(/[^0-9]/g, "");
  return unformattedPhoneValue;
}

export const checkPhoneValidation = (value) => {
  return !String(value)
    .replace("+1", "")
    .replaceAll("-", "")
    .replaceAll(" ", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .match(/^[0-9]{10}$/);
};
