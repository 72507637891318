import React from "react";

const CaretLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8254 2.95241C5.03506 3.13212 5.05934 3.44777 4.87963 3.65744L1.15854 7.99871L4.87963 12.34C5.05934 12.5496 5.03506 12.8653 4.8254 13.045C4.61573 13.2247 4.30008 13.2004 4.12037 12.9908L0.120372 8.3241C-0.0401239 8.13686 -0.0401239 7.86056 0.120372 7.67331L4.12037 3.00664C4.30008 2.79698 4.61573 2.7727 4.8254 2.95241Z"
        fill="#0C376E"
      />
    </svg>
  );
};

export default CaretLeft;
