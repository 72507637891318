import React, { useRef } from "react";

import PropTypes from "prop-types";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { t } from "i18next";

export function OTP({ separator, length, value, onChange, setLoginRedirect }) {
  const inputRefs = useRef(new Array(length).fill(null));
  const { enqueueSnackbar: notification } = useSnackbar();

  const focusInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
  };

  const selectInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (event, currentIndex) => {
    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
      case " ":
        event.preventDefault();
        break;
      case "ArrowLeft":
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case "ArrowRight":
        event.preventDefault();
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case "Delete":
        event.preventDefault();
        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });

        break;
      case "Backspace":
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event, currentIndex) => {
    const currentValue = event.target.value;
    const hasCodeOnlyDigits = /^\d+$/.test(currentValue);

    if (!hasCodeOnlyDigits) {
      notification(t("codeOnlyDigits"), {
        variant: "error",
      });
      return;
    }

    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (
        inputRefs.current[indexToEnter].value &&
        indexToEnter < currentIndex
      ) {
        indexToEnter += 1;
      } else {
        break;
      }
    }
    onChange((prev) => {
      const otpArray = prev.split("");
      const lastValue = currentValue[currentValue.length - 1];
      otpArray[indexToEnter] = lastValue;
      return otpArray.join("");
    });
    if (currentValue !== "") {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (event, currentIndex) => {
    selectInput(currentIndex);
  };

  return (
    <Box
      sx={{ display: "flex", gap: "10px", width: "100%", alignItems: "center" }}
    >
      {new Array(length).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <BaseInput
            slots={{
              input: InputElement,
            }}
            style={{
              display: "inline-flex",
              flexDirection: "column",
              position: "relative",
              minWidth: "0px",
              padding: "0px",
              margin: "0px",
              border: "0px",
              verticalAlign: "top",
            }}
            placeholder="-"
            aria-label={`Digit ${index + 1} of OTP`}
            slotProps={{
              input: {
                type: "tel",
                ref: (ele) => {
                  inputRefs.current[index] = ele;
                },
                onKeyDown: (event) => handleKeyDown(event, index),
                onChange: (event) => handleChange(event, index),
                onClick: (event) => handleClick(event, index),
                onPaste: (event) => handlePaste(event, index),
                onKeyUp: (event) => handleKeyUp(event, index),
                value: value[index] ?? "",
              },
            }}
          />
          {index === length - 1 ? null : separator}
        </React.Fragment>
      ))}
    </Box>
  );

  function handleKeyUp(event) {
    if (event.key === "Enter" && value.length === 4) {
      setLoginRedirect(true);
    }
  }

  function handlePaste(event, currentIndex) {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    // Check if there is text data in the clipboard
    if (clipboardData.types.includes("text/plain")) {
      let pastedText = clipboardData.getData("text/plain");
      pastedText = pastedText.substring(0, length).trim();

      const hasCodeOnlyDigits = /^\d+$/.test(pastedText);

      if (!hasCodeOnlyDigits) {
        notification(t("codeOnlyDigits"), {
          variant: "error",
        });
        return;
      }

      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (
          inputRefs.current[indexToEnter].value &&
          indexToEnter < currentIndex
        ) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otpArray = value.split("");

      for (let i = indexToEnter; i < length; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? " ";
        otpArray[i] = lastValue;
      }

      onChange(otpArray.join(""));
      setLoginRedirect(true);
    }
  }
}

OTP.propTypes = {
  length: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  separator: PropTypes.node,
  value: PropTypes.string.isRequired,
};

const InputElement = styled("input")(
  ({ theme }) => `
    height: 46px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 8px;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
  
    &:hover {
      border-color: #0C376E;
    }
  
    &:focus {
      border-color: #0C376E;
      box-shadow: 0 0 0 1px #0C376E;
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);
