import * as React from "react";
const RemoveCrop = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#4D4D4D"
      fillRule="evenodd"
      d="M5 2a.75.75 0 0 1 .75.75v9c0 1.907.002 3.262.14 4.29.135 1.005.389 1.585.812 2.008.423.423 1.003.677 2.009.812 1.028.138 2.382.14 4.289.14h9a.75.75 0 1 1 0 1.5h-2.25v2.25a.75.75 0 1 1-1.5 0V20.5h-5.306c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87-.153-1.14-.153-2.595-.153-4.433V6.5H2A.75.75 0 0 1 2 5h2.25V2.75A.75.75 0 0 1 5 2Zm10.29 4.64c-1.028-.138-2.383-.14-4.29-.14H8A.75.75 0 0 1 8 5h3.056c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.748.749 1.08 1.698 1.238 2.87.153 1.14.153 2.595.153 4.433v3.056a.75.75 0 1 1-1.5 0v-3c0-1.907-.002-3.261-.14-4.29-.135-1.005-.389-1.585-.812-2.008-.423-.423-1.003-.677-2.009-.812"
      clipRule="evenodd"
    />
    <path
      fill="#4D4D4D"
      fillRule="evenodd"
      d="M21.796 3.204a.698.698 0 0 1 0 .987L3.19 22.796a.698.698 0 0 1-.987-.987L20.81 3.204a.698.698 0 0 1 .987 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default RemoveCrop;
