export const LANGUAGES = {
  es: {
    name: "Spanish",
    nativeName: "Español",
  },
  en: {
    name: "English",
    nativeName: "English",
  },
  af: {
    name: "Afrikaans",
    nativeName: "Afrikaans",
  },
  am: {
    name: "Amharic",
    nativeName: "አማርኛ",
  },
  be: {
    name: "Belarusian",
    nativeName: "Беларуская",
  },
  bn: {
    name: "Bengali",
    nativeName: "বাংলা",
  },
  ja: {
    name: "Japanese",
    nativeName: "日本語",
  },
  zh: {
    name: "Chinese",
    nativeName: "中国人",
  },
  zu: {
    name: "Zulu",
    nativeName: "Zulu",
  },
  yo: {
    name: "Yoruba",
    nativeName: "Yoruba",
  },
  yi: {
    name: "Yiddish",
    nativeName: "יידיש",
  },
  xh: {
    name: "Xhosa",
    nativeName: "isiXhosa",
  },
  vi: {
    name: "Vietnamese",
    nativeName: "Tiếng Việt",
  },
  uz: {
    name: "Uzbek",
    nativeName: "O'zbek",
  },
  ur: {
    name: "Urdu",
    nativeName: "اردو",
  },
  uk: {
    name: "Ukrainian",
    nativeName: "Українська",
  },
  ru: {
    name: "Russian",
    nativeName: "Русский",
  },
  ro: {
    name: "Romanian",
    nativeName: "Română",
  },
  pl: {
    name: "Polish",
    nativeName: "Polski",
  },
  la: {
    name: "Latin",
    nativeName: "Latinus",
  },
  ko: {
    name: "Korean",
    nativeName: "한국어",
  },
  fr: {
    name: "French",
    nativeName: "Français",
  },
  de: {
    name: "German",
    nativeName: "Deutsch",
  },
  ca: {
    name: "Catalan",
    nativeName: "Catalá",
  },
  eo: {
    name: "Esperanto",
    nativeName: "Esperanto",
  },
  fa: {
    name: "Persian",
    nativeName: "فارسی",
  },
  ga: {
    name: "Irish",
    nativeName: "Gaeilge",
  },
};
