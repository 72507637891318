import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../vendor/error-page.svg";
import useStyles from "../documents/styles";
import Header from "../documents/Header";

function Page404() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <div className={classes.errorPage}>
        <Helmet title="404 Error" />
        <Logo />
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          Oops! Page Not Found.
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          The page you are looking for is not available or has been moved. Try a
          different page or go to homepage with the button below.
        </Typography>

        <Button component={Link} to="/" variant="contained" mt={2}>
          Back Home
        </Button>
      </div>
    </>
  );
}

export default Page404;
