import { useParams } from "react-router-dom";

const useAuthParams = () => {
  const { authParams } = useParams();
  const [param, value] = authParams?.split("=") || [];
  const isTypeParam = param === "type";
  const isTicketParam = param === "ticket";
  const hasParams = param && value;
  return { param, value, hasParams, isTypeParam, isTicketParam };
};

export default useAuthParams;
