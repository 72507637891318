import React, { useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Stack } from "@mui/material";
import { t } from "i18next";

import { addSocketListener, socket } from "../../utils/socketHandler";
import { getTicket } from "../../redux/slices/ticketDetailSlice";
import { Header } from "../../components/home/Header";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";

import "./mainPage.less";

function MainPage() {
  const { stages } = useSelector((state) => state.ticket);
  const { components, selectedStage } = useOutletContext();
  const { enqueueSnackbar: notification } = useSnackbar();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuthTicket } = useAuth();

  useEffect(() => {
    socket.emit("rooms:change", [
      { name: params.ticketId ?? localStorage.getItem("phoneNumber") },
    ]);

    const updateListener = (payload) => {
      if (
        payload?.section === "tickets" &&
        (payload?.data?.stage === "Application" ||
          payload?.data?.stage === "Orientation" ||
          payload?.data?.stage === "Consumer")
      ) {
        if (!params.ticketId && payload?.data?.ticketId) {
          setAuthTicket(payload?.data?.ticketId);
          localStorage.removeItem("applicantType");
          navigate(`/ticket/${payload.data.ticketId}`);
        }
        dispatch(getTicket({ id: params.ticketId ?? payload?.data?.ticketId }));
        if (payload?.data?.message) {
          let messageToTranslate = payload?.data?.message;
          if (
            payload?.data?.stage === "Orientation" &&
            stages.some((s) => s.name === "Consumer")
          ) {
            messageToTranslate = "consumerCompleted";
          }
          notification(t(messageToTranslate), {
            variant: "success",
          });
        }
      }
    };

    addSocketListener("update", updateListener);

    return () => {
      socket.off("update", updateListener);
    };
  }, [params]);

  return (
    <div className="main-page-container  max-w-screen-xl mx-auto w-full">
      <Header />
      <Stack direction="column" height={"100%"} width={"100%"}>
        {components[selectedStage]}
      </Stack>
    </div>
  );
}

export default MainPage;
