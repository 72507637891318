import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
// import shadows from "./shadows";

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      // shadows: shadows,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
    {
      palette: {
        primary: {
          main: "#0C376E",
        },
        secondary: {
          main: "#A8195B",
          dark: "#0C376E",
          disabled: "#CCCCCC",
        },
        error: {
          main: "#A61919",
          dark: "#CC8585",
          light: "#FFEBEB",
        },
        success: {
          main: "#31A619",
          dark: "#90CC84",
          light: "#EEFFEB",
        },
      },
    }
  );
};

export default createTheme;
