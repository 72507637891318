import { createGlobalStyle } from "styled-components/macro";

const breakpoints = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
};

const widthAnimation = `
  -webkit-transition: width .5s ease-in-out;
  -moz-transition: width .5s ease-in-out;
  -o-transition: width .5s ease-in-out;
  transition: width .5s ease-in-out;
`;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .user{
    &-name {
      height: 28px;
      margin-bottom: 5px;
      font-size: 24px;
      max-width: 100%;
      font-weight: 700;
      align-items: center;
      font-family: "Inter", sans-serif;
      justify-content: space-between;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }
      button {
        padding: 6px;
        svg {
          width: 16px;
          height: 16px;
          fontsize: 16px;
        }
      }
    }

    &-information {
    gap: 8px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: center;

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
  }}

  .root {
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  .sign-in {
    background: url("/static/img/backgrounds/uploader-bg-mov.svg") no-repeat;
    background-position: center 0px;
    background-size: cover;
    width: 100%;
    display: flex;
    height: 75%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &-wrapper {
      width: 80%;
      color: white;
      display: flex;
      background: none;
      flex-direction: column;

      .brand {
        width: 406px;
        margin-bottom: 46px;
      }
    }
  }
  
  .side {
    background: url("/static/img/backgrounds/upload.svg") no-repeat;
    background-position: 50% 50%;
    background-size: 90%;
    height: 100%;
    width: 0;
    ${widthAnimation}
  }

  .show-more-btn {
    white-space: nowrap;
    width: 100px;
  }

  .code-form {
    width: 70%;
    min-width: 460px;
    gap: 24px;
    display: flex;
    flex-direction: column;
  }

  .phone-code {
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
    background-color: #f5f7fa;

    .wrapper{
      width: 80%;
      height: 100%;
      display: flex;
      max-width: 600px;
      text-align: center;
      align-items: center;
      flex-direction: column;
    }

    img {
      width: 100%;
      max-width: 350px;
      margin: 14px auto;
    }

    .text-wrapper{
      display: flex;
      flex-direction: column;
      max-width: 450px;
    }
  }

  .progress
  {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    &-layout {
      gap: 8px;
      display: flex;
      > div {
        flex: 1;
      }
      button {
        width: 40px;
        height: 40px;
      }
    }
  }
  
  @media (max-width: 599px) {
    .root{
      display: list-item;
    }
    .logo {
      {
        background: url("/arla_signin_blue.svg") no-repeat;
        background-position: center 60%;
        height: 25%;
      }
    }
    .sign-in-wrapper {
      align-items: center;
      text-align: center;
      height: 80%;

      .brand {
        width: 190px;
        margin-bottom: 24px;
      }
    }
    .user-name {
      max-width: calc(100% - 100px);
    }
    .code-form {
      width: 100%;
      min-width: unset;
    }
  }

  @media (min-width: 600px) {
    .sign-in {
      background: url("/static/img/backgrounds/uploader-bg.svg") no-repeat;
      background-position: 100%;
      background-size: cover;
      min-height: 100%;
    }
    
    .user-name-wrapper {
      max-width: calc(90% - 108px);
    }
  }

  @media (min-width: 960px) {
    .sign-in {
      ${widthAnimation}
      width: 70%;
    }
    .side {
      width: 30%;
    }
  }

  @media (min-width: 1280px) {
    .sign-in {
      width: 60%;
    }
    .side {
      background-size: unset;
      width: 40%;
    }
  }
  
  ${Object.keys(breakpoints).map((key) => {
    return `
      .gt-${key} {
        @media (max-width: ${breakpoints[key] - 1}px) {
          display: none !important;
        }
      }
      .lt-${key} {
        @media (min-width: ${breakpoints[key]}px) {
          display: none !important;
        }
      }`;
  })}
`;

export default GlobalStyle;
