import React from "react";

const CaretDown = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.95437 6.17461C3.13408 5.96495 3.44973 5.94067 3.65939 6.12038L8.00066 9.84147L12.3419 6.12038C12.5516 5.94067 12.8672 5.96495 13.047 6.17461C13.2267 6.38428 13.2024 6.69993 12.9927 6.87964L8.32606 10.8796C8.13881 11.0401 7.86251 11.0401 7.67526 10.8796L3.0086 6.87964C2.79893 6.69993 2.77465 6.38428 2.95437 6.17461Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretDown;
