export const handleUnauthenticated = (err) => {
  if (err.response?.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("ticketId");

    if (localStorage.getItem("redirectOnLogout")) {
      window.location = localStorage.getItem("redirectOnLogout");
      return;
    }

    window.location = "/";
    return;
  }
  return Promise.reject(err);
};
