import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";

import { Button, MenuItem, Select, TextField } from "@mui/material";

import { actions } from "../../redux/slices/loadingSlice";
import { ClientLogo } from "./ClientLogo";
import { formatPhoneValue, unformatPhoneValue } from "../../utils/phone";
import { PopularLanguageSelection } from "../shared/PopularLanguageSelection";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";

import "./phoneCodeSelection.less";

const selectedCountryCode = 1;

export const PhoneCodeSelection = ({
  phoneNumber,
  setInputValue,
  nextStepHandler,
  setFoundTickets,
}) => {
  const { createCodeByPhone } = useAuth();
  const { loading } = useSelector((state) => state.loadingReducer);
  const hasPhoneAllDigits = phoneNumber.replace(/[^0-9]/g, "").length === 10;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="flex justify-between gap-4 flex-col h-full">
      <div className="mt-6">
        <PopularLanguageSelection />
      </div>
      <div className="flex flex-col gap-4 w-full pb-2">
        <label className="enter-phone-info font-bold">{t("enterPhone")}</label>
        <div className="flex gap-2 w-full">
          <div className="w-1/4">
            <Select
              value={selectedCountryCode}
              className="w-full relative"
              id="phone-country-code"
              inputProps={{
                readOnly: true,
                IconComponent: () => (
                  <ReactCountryFlag
                    className="emojiFlag"
                    circle
                    countryCode="US"
                    style={{
                      fontSize: "28px",
                      borderRadius: "16px",
                      position: "absolute",
                      left: "1rem",
                    }}
                    aria-label={"US"}
                    svg
                  />
                ),
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            >
              <MenuItem value={1}>+1</MenuItem>
            </Select>
          </div>
          <div className="w-3/4">
            <TextField
              size="small"
              value={phoneNumber}
              type="tel"
              className="w-full"
              id="txtPhone"
              label={t("phoneNumber")}
              variant="outlined"
              onChange={handleInputChange}
              onKeyUp={triggerHandlePhoneVerification}
            />
          </div>
        </div>
        <Button
          size="medium"
          variant="contained"
          color="secondary"
          onClick={handlePhoneVerification}
          disabled={loading || !hasPhoneAllDigits}
        >
          {t("continue")}
        </Button>
      </div>
    </div>
  );

  function triggerHandlePhoneVerification(event) {
    if (event.key === "Enter" && hasPhoneAllDigits) {
      handlePhoneVerification();
    }
  }

  async function handlePhoneVerification() {
    await getTicketsByPhone();
  }

  async function getTicketsByPhone() {
    dispatch(actions.setLoading(true));
    const unformattedPhone = unformatPhoneValue(phoneNumber);
    const resp = await axios.get(
      `tickets/ats/tickets-by-phone/1${unformattedPhone}`
    );
    dispatch(actions.setLoading(false));
    if (!resp.data || resp.data.length === 0) {
      await createCodeByPhone("+1" + unformattedPhone);
      nextStepHandler(2);
    } else {
      setFoundTickets(resp.data);
      nextStepHandler(1);
    }
  }

  function handleInputChange(event) {
    const cleanValue = event.target.value;
    if (cleanValue.replace(/[^0-9]/g, "").length > 10) {
      return;
    }
    setInputValue(formatPhoneValue(cleanValue));
  }
};
