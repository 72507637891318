import React from "react";

const Refresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <g clipPath="url(#clip0_8868_27142)">
        <path
          d="M11.6612 2.90784C11.6648 2.82662 11.672 2.74541 11.672 2.66419C11.6729 2.05088 11.6684 1.43757 11.6738 0.825187C11.6783 0.353767 11.8673 0.0942524 12.2346 0.0242395C12.5965 -0.04484 12.9512 0.22401 12.9926 0.601146C13.0016 0.686095 13.0007 0.771978 13.0007 0.856927C13.0007 2.14516 13.0016 3.4334 13.0007 4.72164C13.0007 5.29948 12.7487 5.5674 12.1959 5.56926C10.9455 5.57206 9.69513 5.57206 8.44473 5.56926C8.13146 5.56833 7.83799 5.48991 7.70566 5.15572C7.50941 4.66003 7.82719 4.19421 8.36281 4.18767C9.08929 4.17927 9.81576 4.18581 10.5422 4.18581C10.6224 4.18581 10.7025 4.18581 10.7898 4.18581C10.7898 4.13913 10.7988 4.10926 10.7889 4.09712C10.2821 3.49781 9.83196 2.84156 9.19821 2.36734C7.86049 1.36662 6.38684 1.12204 4.82857 1.628C2.97143 2.23105 1.80655 3.57343 1.43836 5.53846C1.05577 7.57817 1.72013 9.27995 3.29911 10.5766C4.38026 11.4644 5.62796 11.7537 6.97198 11.5857C7.64174 11.5017 8.27819 11.2627 8.85883 10.8884C9.02987 10.7782 9.19911 10.6606 9.37825 10.5663C9.65732 10.4198 9.985 10.515 10.1731 10.781C10.3469 11.0265 10.3496 11.3785 10.1317 11.5913C9.92739 11.7911 9.69333 11.9666 9.44937 12.1131C8.25659 12.8338 6.98278 13.1279 5.59645 12.9505C4.23713 12.7759 3.05065 12.2382 2.0352 11.2963C1.06297 10.3936 0.458928 9.25754 0.165458 7.9497C-0.12081 6.6708 -0.03619 5.4031 0.42382 4.18114C1.01076 2.62499 2.0127 1.4497 3.46745 0.694497C4.59271 0.111055 5.7819 -0.105518 7.01789 0.0475771C8.64368 0.248281 9.9967 1.01749 11.068 2.30853C11.2417 2.51857 11.4208 2.723 11.5982 2.93024C11.6198 2.92277 11.6405 2.91437 11.6621 2.9069L11.6612 2.90784Z"
          fill="#0C376E"
        />
      </g>
      <defs>
        <clipPath id="clip0_8868_27142">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Refresh;
