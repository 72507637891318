const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        fontWeight: "500",
        height: "36px",
        ...(ownerState.variant === "outlined" && {
          borderRadius: "25px",
        }),
        "&.Mui-disabled": {
          color: "#fff",
          backgroundColor: "#ccc",
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: "46px",
        // borderRadius: "25px !important",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        borderRadius: "25px",
        height: "9px",
        margin: "4px 0",
        backgroundColor: "#F2F2F2",
        "& .MuiLinearProgress": {
          "&-barColorSuccess": {
            borderRadius: "25px",
            backgroundColor: "#90CC84",
          },
        },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        top: "2px !important",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": { borderColor: "#0C376E" },
        "&.variant-white": {
          "*": {
            color: "white",
          },
          ".MuiFormHelperText-root": {
            "&.Mui-error": {
              color: "#CC8585",
            },
            "svg, path": {
              color: "#CC8585",
              fontSize: "16px",
            },
            margin: 0,
            marginTop: "8px",
            display: "flex",
            gap: "5px",
          },
          "input:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
          p: {
            margin: 0,
            marginTop: "8px",
            display: "flex",
            gap: "5px",
          },
          ".Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "white !important",
          },
          ".Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CC8585 !important",
          },
          ".kCFUta ": {
            "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#bdbdbd",
            },
          },
        },
        [`input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active`]: {
          "-webkit-transition":
            "color 9999s ease-out, background-color 9999s ease-out",
          "-webkit-transition-delay": " 9999s",
        },
      },
    },
  },
};

export default components;
