import React from "react";

const Crop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C5.19891 2 5.38968 2.07902 5.53033 2.21967C5.67098 2.36032 5.75 2.55109 5.75 2.75V11.75C5.75 13.657 5.752 15.012 5.89 16.04C6.025 17.045 6.279 17.625 6.702 18.048C7.125 18.471 7.705 18.725 8.711 18.86C9.739 18.998 11.093 19 13 19H22C22.1989 19 22.3897 19.079 22.5303 19.2197C22.671 19.3603 22.75 19.5511 22.75 19.75C22.75 19.9489 22.671 20.1397 22.5303 20.2803C22.3897 20.421 22.1989 20.5 22 20.5H19.75V22.75C19.75 22.9489 19.671 23.1397 19.5303 23.2803C19.3897 23.421 19.1989 23.5 19 23.5C18.8011 23.5 18.6103 23.421 18.4697 23.2803C18.329 23.1397 18.25 22.9489 18.25 22.75V20.5H12.944C11.106 20.5 9.65 20.5 8.511 20.347C7.339 20.189 6.39 19.857 5.641 19.109C4.893 18.36 4.561 17.411 4.403 16.239C4.25 15.099 4.25 13.644 4.25 11.806V6.5H2C1.80109 6.5 1.61032 6.42098 1.46967 6.28033C1.32902 6.13968 1.25 5.94891 1.25 5.75C1.25 5.55109 1.32902 5.36032 1.46967 5.21967C1.61032 5.07902 1.80109 5 2 5H4.25V2.75C4.25 2.55109 4.32902 2.36032 4.46967 2.21967C4.61032 2.07902 4.80109 2 5 2ZM15.29 6.64C14.262 6.502 12.907 6.5 11 6.5H8C7.80109 6.5 7.61032 6.42098 7.46967 6.28033C7.32902 6.13968 7.25 5.94891 7.25 5.75C7.25 5.55109 7.32902 5.36032 7.46967 5.21967C7.61032 5.07902 7.80109 5 8 5H11.056C12.894 5 14.35 5 15.489 5.153C16.661 5.311 17.61 5.643 18.359 6.391C19.107 7.14 19.439 8.089 19.597 9.261C19.75 10.401 19.75 11.856 19.75 13.694V16.75C19.75 16.9489 19.671 17.1397 19.5303 17.2803C19.3897 17.421 19.1989 17.5 19 17.5C18.8011 17.5 18.6103 17.421 18.4697 17.2803C18.329 17.1397 18.25 16.9489 18.25 16.75V13.75C18.25 11.843 18.248 10.489 18.11 9.46C17.975 8.455 17.721 7.875 17.298 7.452C16.875 7.029 16.295 6.775 15.289 6.64"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default Crop;
