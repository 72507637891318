import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLanguage: "en",
};

const language = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.selectedLanguage = payload;
    },
  },
});
export const { actions, reducer } = language;
