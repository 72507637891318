import * as React from "react";

const Application = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 21"
    width={16}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || "currentColor"}
      fillRule="evenodd"
      d="M8 .25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5ZM4.75 5a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM8 11.25c-2.04 0-3.922.47-5.322 1.27C1.3 13.308.25 14.51.25 16s1.05 2.692 2.428 3.48c1.4.8 3.283 1.27 5.322 1.27 2.04 0 3.922-.47 5.322-1.27 1.378-.788 2.428-1.99 2.428-3.48s-1.05-2.692-2.428-3.48c-1.4-.8-3.283-1.27-5.322-1.27ZM1.75 16c0-.72.517-1.517 1.672-2.177C4.556 13.175 6.173 12.75 8 12.75c1.827 0 3.444.425 4.578 1.073 1.155.66 1.672 1.458 1.672 2.177 0 .72-.517 1.517-1.672 2.177-1.134.648-2.751 1.073-4.578 1.073-1.827 0-3.444-.425-4.578-1.073C2.267 17.517 1.75 16.72 1.75 16Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Application;
