import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  const ticket = localStorage.getItem("ticketId");

  if (isAuthenticated) {
    const applicantType = localStorage.getItem("applicantType");
    const navigateTo = ticket ? (
      <Navigate to={`/ticket/${ticket}`} />
    ) : (
      <Navigate to={`/type/${applicantType}`} />
    );
    return navigateTo;
  }

  return <>{children}</>;
}

export default GuestGuard;
