import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "i18next";
import ReactCountryFlag from "react-country-flag";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { POPULAR_LANGUAGES } from "../../utils/popularlanguages";
import { actions } from "../../redux/slices/languageSlice";
import { GlobalIcon } from "../../assets/icons";

import "./popularLanguageSelection.less";

export const PopularLanguageSelection = () => {
  const dispatch = useDispatch();
  const { selectedLanguage } = useSelector((state) => state.languageReducer);
  const [t] = useTranslation();

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={localStorage.getItem("languageKey") ?? selectedLanguage}
      name="radio-buttons-group"
    >
      <div className="flex flex-col gap-2">
        <label
          style={{
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
          className="font-bold"
        >
          <div>
            <GlobalIcon color={"black"} />
          </div>
          {t("selectLanguage")}
        </label>
        {Object.keys(POPULAR_LANGUAGES).map((key) => (
          <div
            key={key}
            className="flex flex-row items-center gap-2 bg-gray language-item cursor-pointer"
            onClick={() => changeLanguageHandler(key)}
          >
            <FormControlLabel
              control={
                <Radio
                  checked={
                    (localStorage.getItem("languageKey") ??
                      selectedLanguage) === key
                  }
                  value={key}
                />
              }
              label={
                <div className="flex flex-row gap-2 items-center">
                  <div>
                    <ReactCountryFlag
                      className="emojiFlag"
                      circle
                      countryCode={POPULAR_LANGUAGES[key].flag}
                      style={{
                        fontSize: "28px",
                        borderRadius: "16px",
                      }}
                      aria-label={POPULAR_LANGUAGES[key].nativeName}
                      svg
                    />
                  </div>
                  {POPULAR_LANGUAGES[key].nativeName}
                </div>
              }
            />
          </div>
        ))}
      </div>
    </RadioGroup>
  );

  function changeLanguageHandler(languageKey) {
    if (!languageKey) {
      return;
    }
    dispatch(actions.setLanguage(languageKey));
    changeLanguage(languageKey);
    localStorage.setItem("languageKey", languageKey);
  }
};
