import React from "react";

const Download = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      {...props}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66536 6.49724C5.66688 6.77338 5.44426 6.99847 5.16812 6.99999C4.4392 7.00401 3.92246 7.02302 3.53034 7.0951C3.15246 7.16455 2.93377 7.27603 2.77159 7.43822C2.58693 7.62287 2.4666 7.88198 2.40082 8.37107C2.33309 8.87457 2.33203 9.54186 2.33203 10.4987V11.1653C2.33203 12.1225 2.33309 12.7898 2.40082 13.2933C2.46659 13.7823 2.58691 14.0416 2.77179 14.2266C2.95597 14.4107 3.21492 14.5309 3.7041 14.5966C4.20774 14.6643 4.87524 14.6653 5.83203 14.6653H11.1654C12.1222 14.6653 12.7897 14.6643 13.2933 14.5966C13.7827 14.5309 14.0416 14.4106 14.2258 14.2264C14.4105 14.0418 14.5308 13.7827 14.5966 13.2935C14.6643 12.7899 14.6654 12.1225 14.6654 11.1653V10.4987C14.6654 9.54186 14.6643 8.87457 14.5966 8.37107C14.5308 7.88198 14.4105 7.62287 14.2258 7.43822C14.0636 7.27603 13.8449 7.16455 13.4671 7.0951C13.0749 7.02302 12.5582 7.00401 11.8293 6.99999C11.5531 6.99847 11.3305 6.77338 11.332 6.49724C11.3336 6.2211 11.5587 5.99848 11.8348 6.00001C12.5559 6.00399 13.1568 6.02131 13.6478 6.11157C14.1531 6.20445 14.5831 6.3813 14.9329 6.73111C15.3343 7.13246 15.5069 7.63768 15.5877 8.23776C15.6654 8.81571 15.6654 9.55049 15.6654 10.4621V11.2019C15.6654 12.1138 15.6654 12.8488 15.5877 13.4268C15.5069 14.027 15.3343 14.5322 14.9329 14.9336C14.5318 15.3347 14.0264 15.5071 13.4264 15.5877C12.8484 15.6654 12.1135 15.6653 11.2019 15.6653H5.79549C4.88387 15.6653 4.14895 15.6654 3.57096 15.5877C2.97099 15.5071 2.46562 15.3347 2.06448 14.9336C1.66335 14.532 1.49047 14.0267 1.40974 13.4266C1.33201 12.8486 1.33202 12.1138 1.33203 11.2019V10.4621C1.33202 9.5505 1.33201 8.81571 1.40974 8.23776C1.49046 7.63768 1.66313 7.13246 2.06448 6.73111C2.41429 6.3813 2.84427 6.20445 3.34956 6.11157C3.8406 6.02131 4.44153 6.00399 5.16261 6.00001C5.43875 5.99848 5.66383 6.2211 5.66536 6.49724Z"
        fill={props.fill || "#000"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49999 11C8.35403 11 8.21535 10.9362 8.12036 10.8254L6.12036 8.49206C5.94065 8.2824 5.96493 7.96675 6.1746 7.78704C6.38426 7.60733 6.69991 7.63161 6.87962 7.84127L7.99999 9.14837L7.99999 1.83333C7.99999 1.55719 8.22385 1.33333 8.49999 1.33333C8.77613 1.33333 8.99999 1.55719 8.99999 1.83333L8.99999 9.14837L10.1204 7.84127C10.3001 7.63161 10.6157 7.60733 10.8254 7.78704C11.035 7.96675 11.0593 8.2824 10.8796 8.49206L8.87962 10.8254C8.78463 10.9362 8.64595 11 8.49999 11Z"
        fill={props.fill || "#000"}
      />
    </svg>
  );
};

export default Download;
